import { Button, Grid, Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Link as NavLink } from 'react-router-dom';

// import AuthSplash from 'components/authSplash/AuthSplash';
// import PhoneInput from 'components/phoneInput/PhoneInput';
// import { getInstitutions } from 'services/userService';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';

import { register } from 'services/authService';
import {
  validateConfirmPassword,
  validateForm,
  validateInput,
} from 'utils/validation';

import Swal from 'sweetalert2';

import AuthStyles from 'styles/auth';
import '../RegisterPage.css';

// import PartnersFooter from 'components/footer/partnersFooter';

import { RegisterUserModel } from 'mapfilm-api';
// import { project } from 'project';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';
import { normalizeMessage } from 'utils/utils';
import GeneralForm from '../forms/RegisterGeneralForm';

interface OwnProps {
  classes: any;
  history: any;
}

interface State {
  form: RegisterUserModel;
  checkTerms: boolean;
  errors: {
    firstNameError: string;
    lastNameError: string;
    usernameError: string;
    emailError: string;
    passwordError: string;
    confirmPasswordError: string;
    //institutionError: string,
    checkTermsError: string;
  };
  showPassword: boolean;
  showConfirmPassword: boolean;
  formErrorMessage: string;
}

const queryClient = new QueryClient();

const RegisterPageGeneralWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RegisterPageGeneral {...props} />
    </QueryClientProvider>
  );
};

const RegisterPageGeneral: React.FC<OwnProps> = ({ classes, history }) => {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    institution: '',
    institutionAddress: '',
    password: '',
    role: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [checkTerms, setCheckTerms] = useState(false);
  const [errors, setErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    usernameError: '',
    emailError: '',
    institutionError: '',
    passwordError: '',
    confirmPasswordError: '',
    checkTermsError: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setInputStateError(`${name}Error`, '');
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setConfirmPassword(value);
    setInputStateError(`${name}Error`, '');
  };

  const inputStyle = (error: string) => {
    const getelement = (errors: any, error: string) => {
      return errors[error];
    };
    const errorValue = getelement(errors, error);
    return errorValue === '' || errorValue === 'undefined'
      ? classes.inputTextfield
      : classes.inputTextfieldError;
  };

  const registerPageMutation = useMutation(
    (registerUserModel: RegisterUserModel) => register(registerUserModel),
    {
      onError: (error: any) => {
        if (error === 'No organization found') {
          Swal.fire({
            //position: 'top-end',
            icon: 'error',
            iconColor: PRIMARY_COLOR,
            text: 'Your email domain is not registered to an institution. Please contact your institution to register in the platform',
            showConfirmButton: false,
          });
        } else {
          setFormErrorMessage(error);
        }
      },
      onSuccess: (data) => {
        // console.log('info', data);

        Swal.fire({
          //position: 'top-end',
          icon: 'success',
          iconColor: SECONDARY_COLOR,
          text: 'Registration successful. An email will be sent to your address to confirm your account.',
          showConfirmButton: false,
        }).then(() => {
          window.location.href = '/auth/login';
        });
      },
    }
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // validate form
    const validation = validateForm(form, confirmPassword);
    let formErrorMessage = '';

    if (form.role === undefined || form.role === '') {
      formErrorMessage = 'Select your role please';
      setFormErrorMessage(formErrorMessage);
      return;
    }
    if (form.institution) {
      formErrorMessage = validateInput('institution', form.institution);
      if (formErrorMessage !== '') {
        setFormErrorMessage(formErrorMessage);
        return;
      }
    }
    if (form.institutionAddress) {
      formErrorMessage = validateInput(
        'institutionAddress',
        form.institutionAddress
      );
      if (formErrorMessage !== '') {
        setFormErrorMessage(formErrorMessage);
        return;
      }
    }

    console.log('validation', validation);

    if (validation === ' ') {
      const registerUserModel: RegisterUserModel = {
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        password: form.password,
        userRole: 'user',
        username: form.username,
        role:
          form.role === 'buyer' || form.role === 'seller'
            ? form.role
            : undefined,
        institution: form.institution,
        institutionAddress: form.institutionAddress,
      };

      registerPageMutation.mutate(registerUserModel);
    } else formErrorMessage = normalizeMessage(validation) + ' is invalid!';

    setFormErrorMessage(formErrorMessage);
  };

  const setInputStateError = (errorName: string, errorValue: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorName]: errorValue,
    }));
  };

  const onBlurHandle = async (name: string) => {
    // Validate
    const errorName = `${name}Error`;
    let errorValue = '';
    const getelement = (form: any, name: string) => {
      return form[name];
    };

    const inputValue = getelement(form, name);

    if (!(inputValue === '' || typeof inputValue === undefined)) {
      if (name === 'confirmPassword') {
        errorValue = validateConfirmPassword(form.password, confirmPassword);
      } else {
        errorValue = validateInput(name, inputValue);
      }
    }

    setInputStateError(errorName, errorValue);
  };

  const handleChangeRoleType = (newValue: 'buyer' | 'seller') => {
    setForm((prevForm) => ({
      ...prevForm,
      role: newValue,
    }));
  };

  return (
    <>
      <div className={classes.authFormContainer}>
        <div className={classes.authFormBox}>
          <div className={classes.titleContainer}>
            <Typography
              component='h1'
              variant='h5'
              classes={{ root: classes.registerTitle }}
            >
              <span className={classes.changeColor}>Create</span> an account
            </Typography>
            <p className={classes.aboveSubtitle}>
              to have the complete solution in your hands
            </p>
          </div>
          <Typography
            className={classes.authError}
            component='h1'
            variant='h6'
            style={{ color: 'red' }}
          >
            {formErrorMessage}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid className={classes.formContainer} container spacing={3}>
              <GeneralForm
                classes={classes}
                form={form}
                inputStyle={inputStyle}
                handleChange={handleChange}
                handleChangeRoleType={handleChangeRoleType}
                onBlurHandle={onBlurHandle}
                errors={errors}
                confirmPassword={confirmPassword}
                showPassword={showPassword}
                showConfirmPassword={showConfirmPassword}
                handlePasswordChange={handlePasswordChange}
                handleClickShowPassword={handleClickShowPassword}
                handleClickShowConfirmPassword={handleClickShowConfirmPassword}
              />
            </Grid>
            <div
              className={classes.registerOptions}
              style={{ marginTop: '30px' }}
            >
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='default'
                className={classes.submit}
                disabled={registerPageMutation.isLoading}
                onClick={(e: any) => handleSubmit(e)}
              >
                {registerPageMutation.isLoading ? (
                  <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                    <CircularProgress
                      sx={{
                        color: PRIMARY_COLOR,
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Box>
                ) : (
                  'Sign up'
                )}
              </Button>

              <div className={classes.loginLinkContainer}>
                <Typography className={classes.authTxt}>
                  {'Already have an account?'}
                </Typography>
                <Link
                  className={classes.authLink}
                  component={NavLink}
                  to='/auth/login'
                >
                  Sign in
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default withStyles(AuthStyles as any)(RegisterPageGeneralWrapper);
