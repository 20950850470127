import {
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { NavLink } from 'react-router-dom';
import { Button } from 'rsuite';
import { isBuyer, isSeller } from 'services/authService';

import combineStyles from 'utils/combineStyles';

import AddIcon from '@material-ui/icons/Add';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

import { TypeContent } from 'models/enums';
import DownloadsCounter from 'pages/product/DownloadsCounter';
import { project } from 'project';

const LibraryHeader = ({
  classes,
  searchQuery,
  setSearchQuery,
  productsLength,
  typeContent,
  collectionInfo,
}: any) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchQuery(event.target.value);
  };

  return (
    <>
      {typeContent === TypeContent.COLLECTIONFILMS && (
        <NavLink to='/collections' className={classes.backLink}>
          <NavigateBeforeIcon className={classes.backIcon} />
          BACK
        </NavLink>
      )}
      <div
        className={`${classes.headerContainer} ${classes.marginHeaderContainer}`}
      >
        <Typography component='h4' className={classes.dashboardTitle}>
          {isSeller() ? (
            <>
              {typeContent === TypeContent.FILMS
                ? 'My Films '
                : typeContent === TypeContent.COLLECTIONFILMS
                  ? `${collectionInfo.name} `
                  : 'My Collections '}
              <span className={classes.numberResults}>
                ({productsLength} {productsLength === 1 ? 'result' : 'results'})
              </span>
            </>
          ) : (
            <>
              {typeContent === TypeContent.FILMS
                ? 'Films '
                : typeContent === TypeContent.COLLECTIONFILMS
                  ? `${collectionInfo.name} `
                  : 'Collections '}
              <span className={classes.numberResults}>
                ({productsLength} {productsLength === 1 ? 'result' : 'results'})
              </span>
            </>
          )}
        </Typography>

        <TextField
          variant='outlined'
          value={searchQuery}
          onChange={(e: any) => handleSearch(e)}
          className={classes.searchField}
          placeholder='Search'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {isSeller() ? (
          <div className={`${classes.buttonsContainer} ${classes.buttonsOrg}`}>
            {typeContent === TypeContent.FILMS ||
            typeContent === TypeContent.COLLECTIONFILMS ? (
              <>
                <NavLink
                  className={classes.dashLinkBtn}
                  style={{ marginRight: '0' }}
                  to={
                    typeContent === TypeContent.COLLECTIONFILMS
                      ? `/collection/${collectionInfo.id}/movie/create`
                      : '/movie/create'
                  }
                >
                  <Button
                    className={classes.dashboardButton}
                    variant='outlined'
                  >
                    <span className='btn-txt'>Add Film</span>{' '}
                    <AddIcon className={classes.btnIcon} />
                  </Button>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  className={classes.dashLinkBtn}
                  style={{ marginRight: '0' }}
                  to='/collection/create'
                >
                  <Button
                    className={classes.dashboardButton}
                    variant='outlined'
                  >
                    <span className='btn-txt'>Add Collection</span>{' '}
                    <AddIcon className={classes.btnIcon} />
                  </Button>
                </NavLink>
              </>
            )}
          </div>
        ) : (
          ''
        )}
        {isBuyer() && project === 'mfh' ? <DownloadsCounter /> : ''}
      </div>
    </>
  );
};

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default withStyles(combinedStyles as any)(LibraryHeader);
