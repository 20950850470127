import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';

interface CollapsableDescriptionProps {
  description: string;
  classes?: any;
}

const CollapsableDescription: React.FC<CollapsableDescriptionProps> = ({
  description,
  classes,
}) => {
  return (
    <div style={{ marginBottom: '16px' }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='description-content'
          id='description-header'
        >
          <p className={classes?.collectionDescription}>Description</p>
        </AccordionSummary>
        <AccordionDetails>
          <p className={classes?.productSinopsis}>
            {description || 'No description available.'}
          </p>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CollapsableDescription;
