import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';

import AutocompleteInputSelects from 'components/controls/autocompleteInput/AutocompleteInputSelects';
import DropzoneArea from 'components/controls/dropzone/Dropzone';
import TextArea from 'components/controls/textarea/TextArea';
import TextInput from 'components/controls/textInput/TextInput';

import { useQuery } from 'react-query';
import { isSeller } from 'services/authService';
import { getAllProducts } from 'services/productService';
import Styles from 'styles/dashboard';

interface Props {
  collection: any;
  handleDrop: any;
  handleChange: any;
  handleFilmSelect?: any;
  edit: boolean;
}

const CollectionDetails: React.FC<Props> = ({
  collection,
  handleDrop,
  handleChange,
  handleFilmSelect,
  edit,
}) => {
  const [selectedFilms, setSelectedFilms] = useState<any[]>(
    collection.products || []
  );
  const [films, setFilms] = useState<any[]>([]);

  const {
    data: dataFilms,
    isLoading: loadingFilms,
    isError: errorFilms,
  } = useQuery(['films', isSeller()], () => getAllProducts(isSeller()), {
    onSuccess: (data) => {
      const products = data.products;
      setFilms(products);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    setSelectedFilms(collection.products || []);
  }, [collection.products]);

  const handleFilmSelectVal = (event: any, newValue: any[]) => {
    setSelectedFilms(newValue);
    handleFilmSelect(newValue);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={4}>
          <DropzoneArea
            onDrop={handleDrop}
            image={collection.poster}
            edit={edit}
            product={collection}
          />
        </Grid>

        <Grid item sm={8}>
          <TextInput
            name={edit === false ? 'title' : 'name'}
            type='text'
            pageType='product'
            label='Title *'
            placeholder='Enter the film title'
            value={collection.title ?? collection.name}
            onChange={handleChange}
          />

          <div style={{ marginTop: '20px' }}>
            <TextArea
              name={edit === false ? 'synopsis' : 'description'}
              type='text'
              label='Synopsis'
              pageType='product'
              placeholder='Enter the description of the film'
              value={
                collection.synopsis
                  ? collection.synopsis
                  : collection.description || ''
              }
              onChange={handleChange}
              multiline={true}
              rows={5}
            />
          </div>
        </Grid>

        <Grid item sm={12}>
          <AutocompleteInputSelects
            id='products'
            label='Add Existing Films'
            name='products'
            pageType='product'
            value={selectedFilms}
            options={films}
            placeholder='Select films to add'
            disabled={false}
            auth={true}
            required={true}
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={handleFilmSelectVal}
            selectedOptions={selectedFilms}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(Styles as any)(CollectionDetails);
