import clsx from 'clsx';
import { useEffect, useState } from 'react';
// import { useImage } from 'react-image';
import { withStyles } from '@material-ui/styles';
import { Img } from 'react-image';

import combineStyles from 'utils/combineStyles';

import DefaultPoster from 'assets/images/default-image.jpg';
// import EditIcon from 'assets/images/edit-icon.svg';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';
import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { TypeContent } from 'models/enums';

interface Props {
  classes?: any;
  movie: boolean;
  title: string;
  poster?: string;
  director?: string;
  synopsis?: string;
  year?: number;
  duration?: number;
  genres?: any;
  genreList?: any;
  isSimpleView?: boolean;
  typeContent: any;
  nProducts?: number;
}

function ItemProduct(props: Props) {
  const {
    classes,
    poster,
    title,
    director,
    movie,
    synopsis,
    genres,
    nProducts,
    year,
    duration,
    isSimpleView,
    typeContent,
  } = props;
  const [srcImg] = useState(poster ? poster : DefaultPoster);

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sliceGenres =
    screenSize <= 1500 && genres !== undefined
      ? genres?.slice(0, 1)
      : screenSize >= 1800
        ? genres?.slice(0, 3)
        : genres?.slice(0, 2);

  return (
    <>
      {!isSimpleView ? (
        <Card className={classes.cardStyles}>
          <CardMedia
            component='div'
            sx={{ position: 'relative', width: '30%', padding: '28px' }}
          >
            <div className={clsx(classes.imageHover, 'movie-image-hover')}>
              <MovieOutlinedIcon />
            </div>
            <Img
              src={[srcImg, DefaultPoster]}
              className={movie ? classes.productImage : classes.collectionImage}
            />
          </CardMedia>

          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <div className={classes.productHeader}>
                <div className={classes.productTitle}>{title}</div>
                {typeContent === TypeContent.FILMS ||
                typeContent === TypeContent.COLLECTIONFILMS ? (
                  <div className={classes.productGenre}>
                    {sliceGenres
                      ? sliceGenres.map((genre: string, index: number) => (
                          <span key={index} className={classes.genreItem}>
                            {genre}
                          </span>
                        ))
                      : ''}
                  </div>
                ) : (
                  <span className={classes.genreItem}>
                    {nProducts} {nProducts === 1 ? 'film' : 'films'}
                  </span>
                )}
              </div>
              <div className={classes.productDirector}>{director}</div>
              <div className={classes.productDirector}>
                {year && duration
                  ? `${year} | ${duration} min`
                  : year
                    ? year
                    : duration
                      ? duration + ' min'
                      : ''}
              </div>
              <div className={classes.productSinopsis}>{synopsis}</div>
            </CardContent>
          </Box>
        </Card>
      ) : (
        <div className={classes.productItem}>
          <div className={classes.productImgContainer}>
            <Img
              src={[srcImg, DefaultPoster]}
              className={movie ? classes.productImage : classes.collectionImage}
            />
            <div
              className={clsx(classes.imageHoverSimple, 'movie-image-hover')}
            >
              <MovieOutlinedIcon />
            </div>
          </div>

          <div className={classes.productTitle}>{title}</div>
          {typeContent === TypeContent.COLLECTIONS && (
            <div className={classes.productTitleSimple}>
              {nProducts} {nProducts === 1 ? 'film' : 'films'}
            </div>
          )}
        </div>
      )}
    </>
  );
}

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default withStyles(combinedStyles)(ItemProduct);
