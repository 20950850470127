import CloseIcon from '@mui/icons-material/Close';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import { CircularProgress, FormLabel, Grid, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';

import React, { useState } from 'react';
import Styles from 'styles/dashboard';

import CheckIcon from 'assets/icons/checkIcon.svg';
import i1 from 'assets/icons/icon1.svg';
import i2 from 'assets/icons/icon2.svg';
import i3 from 'assets/icons/icon3.svg';

import Box from '@mui/material/Box';

import SubtitlesDropzone from 'components/controls/dropzone/SubtitlesDropzone';
import { ProductVersionForm } from 'models/ProductsModel';
import { languages_list } from 'utils/utils';

import AutocompleteInput from 'components/controls/autocompleteInput/AutocompleteInput';
import { BaseProduct } from 'mapfilm-api';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

interface SubtitlesFormProps {
  product: BaseProduct;
  formData: ProductVersionForm;
  index: number;
  classes: any;
  handleInputChange: any;
  handleDropChange: any;
  handleFilmVersionAdd: (index: number, uuid: string) => void;
  handleRemoveForm: any;
}

const MediaForm: React.FC<SubtitlesFormProps> = ({
  formData,
  product,
  index,
  classes,
  handleInputChange,
  handleDropChange,
  handleFilmVersionAdd,
  handleRemoveForm,
}) => {
  // State for managing selected language
  const [selectedLangValue, setSelectedLangValue] = useState(null);

  // Mutation setup for media upload
  //   const mutation = useMutation(
  //     async (data: ProductVersionForm) => {
  //       if (!data.video) throw new Error('No video file selected');

  //       return createProductMedia(data.version, data.video, data.subtitle || []);
  //     },
  //     {
  //       mutationKey: 'createProductMedia',
  //       onError: (error) => {
  //         console.error('Upload error:', error);
  //       },
  //     }
  //   );

  //   const isMutating = useIsMutating({
  //     mutationKey: 'createProductMedia',
  //     exact: true,
  //   });

  //   useEffect(() => {
  //     if (mutation.isSuccess) {
  //       handleFilmVersionAdd(index, mutation.data);
  //     }
  //   }, [mutation.isSuccess, mutation.data, index, handleFilmVersionAdd]);

  //   const handleUploadClick = () => {
  //     mutation.mutate(formData);
  //   };

  const handleLangChange = (event: any, newValue: any) => {
    setSelectedLangValue(newValue);
    console.log('newVal', newValue);
    handleInputChange(index, 'language', newValue ? newValue.code : '');
  };

  return (
    <div key={index}>
      <div className={classes.addMediaBody}>
        <div className={classes.iconContainer}>
          <IconButton
            onClick={() => handleRemoveForm(index)}
            sx={{ zIndex: 2 }}
          >
            <CloseIcon sx={{ color: PRIMARY_COLOR }} />
          </IconButton>
        </div>

        <Grid container>
          <Grid item xs={12} container className={classes.labelRow}>
            <Grid item xs={4} className={classes.firstColumn}>
              <img src={i1} className={classes.numberIcons} />
              <FormLabel component='legend' className={classes.labelBlack}>
                Language
              </FormLabel>
            </Grid>
            <Grid item xs={4} className={classes.secColumn}>
              <img src={i2} className={classes.numberIcons} />
              <FormLabel component='legend' className={classes.labelBlack}>
                Choose File
              </FormLabel>
            </Grid>
            <Grid item xs={4} className={classes.thirdColumn}>
              <img src={i3} className={classes.numberIcons} />
              <FormLabel component='legend' className={classes.labelBlack}>
                Upload Subtitles
              </FormLabel>
            </Grid>
          </Grid>

          <Grid item xs={12} container className={classes.inputRow}>
            <Grid item xs={4}>
              <AutocompleteInput
                id='language'
                name='language'
                label=''
                pageType='product'
                placeholder='Select subtitle language'
                options={languages_list}
                value={selectedLangValue || null}
                onChange={(event, newValue) =>
                  handleLangChange(event, newValue ? newValue : '')
                }
                auth={true}
                getOptionLabel={(option) =>
                  option && option.name ? option.name : ''
                }
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                required={true}
              />
            </Grid>

            <Grid item xs={4} className={classes.secColumn}>
              <SubtitlesDropzone
                setPropFile={(acceptedFiles) =>
                  handleDropChange(index, acceptedFiles)
                }
                propFile={formData.subtitle}
              />
            </Grid>

            <Grid item xs={4} className={classes.thirdColumn}>
              <button
                className={
                  !formData.video
                    ? classes.disButtonUploadVid
                    : classes.buttonUploadVid
                }
                type='submit'
                // onClick={handleUploadClick}
                disabled={
                  !formData.video ||
                  formData.version === '' ||
                  formData.vizziID !== undefined ||
                  //   mutation.isLoading ||
                  formData.status === 'UPLOADING'
                }
              >
                {
                  // mutation.isLoading ||
                  formData.status === 'UPLOADING' &&
                  formData.vizziID === undefined ? (
                    <Box sx={{ display: 'inline' }}>
                      <CircularProgress
                        sx={{
                          color: THIRD_COLOR,
                          width: '25px !important',
                          height: '25px !important',
                        }}
                      />
                    </Box>
                  ) : //   : mutation.isError ? (
                  //     <img src={ErrorIcon} />
                  //   )
                  formData.vizziID ? (
                    <img src={CheckIcon} />
                  ) : (
                    <>
                      Upload
                      <IconButton
                        sx={{
                          zIndex: '1',
                        }}
                        disabled={!formData.video || formData.version === ''}
                      >
                        <PublishOutlinedIcon sx={{ color: THIRD_COLOR }} />
                      </IconButton>
                    </>
                  )
                }
              </button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(Styles)(MediaForm);
