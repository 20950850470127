import { createStyles } from '@material-ui/styles';

import {
  FOURTH_COLOR,
  HOVER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  THIRD_COLOR,
} from 'styles/colors';

export const useStyles = () =>
  createStyles({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 50,
    },
    headerWithoutMargin: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    footerContainer: {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      '& >*': {
        marginLeft: 24,
      },
    },
    // addMediaContainer: {
    //   display: 'flex',
    //   alignItems: 'center',
    // },
    addMediaContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },

    labelRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    inputRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    //--------------------------------
    dashboardTitle: {
      fontSize: 36,
      fontWeight: 700,
      color: PRIMARY_COLOR,
    },
    dashboardSubtitle: {
      fontSize: 30,
      fontWeight: 700,
      color: PRIMARY_COLOR,
    },
    productStepper: {
      padding: '30px',
      '& .MuiStepLabel-root .Mui-active': {
        color: SECONDARY_COLOR,
      },
      '& .MuiStepLabel-root .Mui-completed': {
        color: SECONDARY_COLOR,
      },
    },
    dashboardSubSubtitle: {
      color: SECONDARY_COLOR,
      fontSize: 20,
      fontWeight: 'bold',
      letterSpacing: '0.04em',
      marginBottom: 20,
      marginTop: 20,
    },
    dashboardSubtitleBlue: {
      fontSize: 30,
      fontWeight: 700,
      color: SECONDARY_COLOR,
      width: '600px',
    },
    dashLinkBtn: {
      color: THIRD_COLOR,
      '&:hover': {
        color: THIRD_COLOR,
        textDecoration: 'none',
      },
    },
    headerContainerCollection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
      marginBottom: '5px',
    },
    dashboardButton: {
      minWidth: 160,
      height: 40,
      paddingRight: 20,
      paddingLeft: 20,
      backgroundColor: PRIMARY_COLOR,
      color: THIRD_COLOR,
      border: 'none',
      borderRadius: 5,
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      letterSpacing: '0.02em',
      '& span': {
        // marginTop: '2px',
        // marginLeft: '10px',
      },
      '&.Mui-disabled': {
        color: THIRD_COLOR,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        border: 'none',
      },
      '&:hover': {
        backgroundColor: SECONDARY_COLOR,
        color: THIRD_COLOR,
      },
    },
    buttonUploadVid: {
      width: '80%',
      height: 46,
      paddingRight: 20,
      paddingLeft: 20,
      backgroundColor: PRIMARY_COLOR,
      color: THIRD_COLOR,
      border: 'none',
      borderRadius: 5,
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '0.02em',
      '&:hover': {
        backgroundColor: FOURTH_COLOR,
      },
    },
    disButtonUploadVid: {
      width: '80%',
      height: 46,
      paddingRight: 20,
      paddingLeft: 20,
      backgroundColor: '#ADADAD',
      color: THIRD_COLOR,
      border: 'none',
      borderRadius: 5,
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '0.02em',
    },
    dashboardButtonAddMedia: {
      minWidth: 160,
      height: 40,
      paddingRight: 20,
      paddingLeft: 20,
      backgroundColor: SECONDARY_COLOR,
      color: PRIMARY_COLOR,
      border: 'none',
      borderRadius: 5,
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '0.02em',
      '&.Mui-disabled': {
        color: THIRD_COLOR,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        border: 'none',
      },
      '&:hover': {
        backgroundColor: HOVER_COLOR,
      },
      float: 'right',
      marginLeft: 'auto',
      marginTop: '60px',
    },
    dashboardMargin: {
      marginTop: 30,
    },
    secondaryBtn: {
      minWidth: 160,
      height: 40,
      paddingRight: 20,
      paddingLeft: 20,
      backgroundColor: SECONDARY_COLOR,
      color: THIRD_COLOR,
      border: `2px solid ${SECONDARY_COLOR}`,
      borderRadius: 5,
      textTransform: 'capitalize',
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: '0.02em',
      '&:hover': {
        color: THIRD_COLOR,
        backgroundColor: HOVER_COLOR,
      },
    },
    btnSpaces: {
      marginRight: '24px',
    },
    btnIcon: {
      marginLeft: 5,
    },
    customGrid: {
      display: 'table !important',
    },
    txtAddMedia: {
      color: SECONDARY_COLOR,
      fontSize: 20,
      fontWeight: 'bold',
      marginLeft: '60px',
      marginRight: '60px',
      marginTop: '25px',
    },
    addMediaBody: {
      position: 'relative',
      paddingLeft: 20,
      paddingRight: 10,
      paddingBottom: 25,
      backgroundColor: THIRD_COLOR,
      border: `1px solid ${SECONDARY_COLOR}`,
      borderRadius: 5,
      minHeight: 0,
      marginTop: '16px',
    },
    buttonsContainer: {
      paddingTop: '20px',
      marginRight: 'auto',
      // marginRight: '0',
      display: 'flex',
    },
    productBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    lineTop: {
      marginTop: '10px',
      marginBottom: '30px',
      width: '100%',
    },
    lineBottom: {
      marginTop: '60px',
      width: '100%',
    },
    buttonsOrg: {
      display: 'flex',
      justifyContent: 'flex-end',
      // paddingBottom: '15px',
    },
    authError: {
      color: 'red',
    },
    boxWidth: {
      width: '100%',
    },
    marginHeaderContainer: {
      marginBottom: '15px',
    },
    iconContainer: {
      textAlign: 'right',
      marginLeft: '60px',
      marginTop: '5px',
    },
    animsContainer: {
      textAlign: 'right',
      marginLeft: '85px',
      marginTop: '25px',
    },
    uploadIconContainer: {
      textAlign: 'right',
      //marginLeft: '51px',
      marginLeft: '75px',
      marginTop: '25px',
    },
    labelBlack: {
      fontSize: 16,
      fontWeight: 700,
      color: `${PRIMARY_COLOR} !important`,
      textTransform: 'uppercase',
      lineHeight: '19px !important',
      // marginTop: '24px',
      // paddingBottom: 8,
      '&.Mui-focused': {
        color: `${PRIMARY_COLOR} !important`,
      },
      '&.Mui-error': {
        color: PRIMARY_COLOR,
      },
      '&.MuiFormLabel-root': {
        fontWeight: 700,
      },
    },
    firstColumn: {
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
    },
    secColumn: {
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
    },
    thirdColumn: {
      paddingLeft: 20,
      display: 'flex',
      alignItems: 'center',
    },
    numberIcons: {
      verticalAlign: 'middle',
      height: 'auto',
      maxWidth: '100%',
      marginRight: '20px',
    },
    btnClose: {
      minWidth: '160 !important',
      height: '40 !important',
      paddingRight: '20 !important',
      paddingLeft: '20 !important',
      backgroundColor: `${PRIMARY_COLOR} !important`,
      color: `${THIRD_COLOR} !important`,
      border: 'none !important',
      borderRadius: 5,
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      letterSpacing: '0.02em',
      '&.Mui-disabled': {
        color: THIRD_COLOR,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        border: 'none',
      },
      '&:hover': {
        backgroundColor: `${SECONDARY_COLOR} !important`,
        color: `${THIRD_COLOR} !important`,
      },
    },
    downloadBtn: {
      minWidth: 160,
      height: 40,
      paddingRight: `20 !important`,
      paddingLeft: `20 !important`,
      backgroundColor: `${SECONDARY_COLOR} !important`,
      color: `${THIRD_COLOR} !important`,
      border: `2px solid ${SECONDARY_COLOR} !important`,
      borderRadius: '0 5px 5px 0 !important',
      textTransform: 'capitalize',
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: '0.02em',
      '&:hover': {
        color: `${THIRD_COLOR} !important`,
        backgroundColor: `${HOVER_COLOR} !important`,
      },
    },
    versionBox: {
      border: '1px solid #000000',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    versionText: {
      paddingRight: '8px',
      padding: '4px 8px',
    },
    iconsAlignment: {
      marginLeft: '10px',
    },
    menuStyle: {
      '& .MuiPaper-root': {
        backgroundColor: FOURTH_COLOR,
        borderRadius: '5px 5px 5px 5px !important',
      },
      '& .MuiMenu-list': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      marginTop: '5px',
    },
    downloadImageCell: {
      textAlign: 'center',
      verticalAlign: 'middle',
      height: '100%',
    },
    downloadImage: {
      display: 'inline-block',
      verticalAlign: 'middle',
      position: 'relative',
      height: '80px',
      borderRadius: '10px',
      objectFit: 'cover',
      zIndex: 1,
    },
    goFilmLink: {
      color: SECONDARY_COLOR,
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      '&:hover': {
        textDecoration: 'none',
        color: FOURTH_COLOR,
      },
      '&:focus': {
        textDecoration: 'none',
        color: FOURTH_COLOR,
      },
    },
    switchText: {
      color: PRIMARY_COLOR,
      fontSize: '16px',
    },
    publishDiv: {
      display: 'grid',
      justifyContent: 'flex-end',
    },
    backLink: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: '#000',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        color: SECONDARY_COLOR,
        textDecoration: 'none',
      },
    },
    backIcon: {
      fontSize: '16px',
      marginRight: '4px',
    },
  });

export const FilmCounterDownload = {
  filmCounter: {
    marginLeft: '20px',
    backgroundColor: `${THIRD_COLOR} !important`,
    padding: '6px',
    borderRadius: '5px',
    alignItems: 'center',
    text: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& h3': {
        fontWeight: 'bold',
        fontSize: '24px',
      },
      '& h4': {
        fontWeight: 300,
        fontSize: '18px',
        marginTop: '5px',
      },
    },
  },
};

export default useStyles;
