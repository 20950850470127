import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { SidebarItem, UserModel } from 'models';
import { RootState } from 'redux/store';
import { isBuyer, isSeller } from 'services/authService';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MovieIcon from '@material-ui/icons/Movie';
import PersonIcon from '@material-ui/icons/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import TheatersIcon from '@mui/icons-material/Theaters';
import SidebarStyles from './SidebarStyles';

import { project } from 'project';

interface OwnProps {
  classes?: any;
  open: boolean;
  handleDrawerClose: (payload: any) => void;
}

interface StateProps {
  user: UserModel;
}

type Props = StateProps & OwnProps;

const isMovieActive = (match: any, location: any) => {
  const pathname: string = location.pathname;
  if (pathname === '/movies' || pathname === '/catalogue') {
    return true;
  } else if (
    pathname.startsWith('/movies') ||
    pathname.startsWith('/catalogue')
  ) {
    return true;
  } else return false;
};

const LeftSideBar: React.FC<Props> = ({ classes, user }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [libraryOpen, setLibraryOpen] = useState(true);
  const [activeSubitem, setActiveSubitem] = useState<
    'collections' | 'films' | null
  >('collections');

  const mainListItems: SidebarItem[] = [];

  if (user.role !== 'admin') {
    mainListItems.push({
      name: isSeller()
        ? project === 'scene'
          ? 'Library'
          : 'My Films'
        : project === 'scene'
          ? 'Library'
          : 'Films',
      url:
        project === 'scene'
          ? activeSubitem === 'collections'
            ? '/collections'
            : '/catalogue'
          : '/catalogue',
      icon: <MovieIcon />,
      isActive: isMovieActive,
    });

    if (isSeller() && project !== 'mfh') {
      mainListItems.push({
        name: 'My Bespoke Licenses',
        url: '/myBespokeLicenses',
        icon: <AssignmentTurnedInIcon />,
      });
    }

    if (project !== 'mfh') {
      mainListItems.push({
        name: 'My Pending Licenses',
        url: '/myPendingLicenses',
        icon: <AssignmentTurnedInIcon />,
      });
    }

    if (isBuyer() && project === 'mfh') {
      mainListItems.push({
        name: 'My Downloads',
        url: '/myDownloads',
        icon: <TheatersIcon />,
      });
    }

    if (user.role !== 'user') {
      mainListItems.push({
        name: 'My Signed Licenses',
        url: '/myCustomLicenses',
        icon: <AssignmentIcon />,
      });
    }
    mainListItems.push({
      name: 'Profile',
      url: '/profile',
      icon: <PersonIcon />,
    });
  }

  if (user.role === 'admin') {
    mainListItems.push({
      name: 'Management',
      url: '/management',
      icon: <GroupsIcon />,
    });
  }

  return (
    <>
      <Drawer
        variant='permanent'
        classes={{
          paper: `${classes.sidebar} ${collapsed ? classes.sidebarCollapsed : ''}`,
        }}
        open={true}
      >
        <List className={classes.itemsContainer}>
          <div
            className={`${collapsed ? classes.toolbarLogo : classes.toolbarLogoCollapsed}`}
          >
            <IconButton
              onClick={() => {
                setCollapsed(!collapsed);
                setLibraryOpen(false);
              }}
            >
              {collapsed ? (
                <MenuIcon style={{ color: 'white' }} />
              ) : (
                <ChevronLeftIcon style={{ color: 'white' }} />
              )}
            </IconButton>
          </div>

          {/* Main List Items */}
          {mainListItems.map((item: SidebarItem, index: number) => (
            <div key={index}>
              {item.name === 'Library' ? (
                <>
                  <NavLink
                    key={index}
                    className={`${classes.sidebarItemLink} libraryText`}
                    activeClassName='active'
                    isActive={(match, location) =>
                      location.pathname.startsWith('/collections') ||
                      location.pathname.startsWith('/collection') ||
                      location.pathname.startsWith('/catalogue') ||
                      location.pathname.startsWith('/movies') ||
                      location.pathname.startsWith('/movie')
                    }
                    to={item.url}
                  >
                    <ListItem
                      button
                      onClick={() => setLibraryOpen(!libraryOpen)}
                      className={classes.sidebarItem}
                    >
                      <ListItemIcon className={classes.sidebarItemIcon}>
                        {item.icon}
                      </ListItemIcon>
                      {!collapsed && (
                        <ListItemText
                          classes={{ primary: classes.sidebarItemTxt }}
                          primary={item.name}
                        />
                      )}
                      {!collapsed &&
                        (libraryOpen ? <ExpandLess /> : <ExpandMore />)}
                    </ListItem>
                    {!collapsed && (
                      <Collapse in={libraryOpen} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                          <NavLink
                            to='/collections'
                            className={classes.sidebarItemLink}
                            activeClassName='active activeSub'
                            isActive={(match, location) =>
                              location.pathname.startsWith('/collections') ||
                              location.pathname.startsWith('/collection')
                            }
                            onClick={() => setActiveSubitem('collections')}
                          >
                            <ListItem button className={classes.sidebarItem}>
                              <ListItemIcon
                                className={classes.sidebarItemIcon}
                              />
                              <ListItemText
                                classes={{ primary: classes.sidebarItemTxt }}
                                primary='Collections'
                              />
                            </ListItem>
                          </NavLink>
                          <NavLink
                            to='/catalogue'
                            className={classes.sidebarItemLink}
                            activeClassName='active activeSub'
                            isActive={(match, location) =>
                              location.pathname.startsWith('/catalogue') ||
                              location.pathname.startsWith('/movies') ||
                              location.pathname.startsWith('/movie')
                            }
                            onClick={() => setActiveSubitem('films')}
                          >
                            <ListItem button className={classes.sidebarItem}>
                              <ListItemIcon
                                className={classes.sidebarItemIcon}
                              />
                              <ListItemText
                                classes={{ primary: classes.sidebarItemTxt }}
                                primary='Films'
                              />
                            </ListItem>
                          </NavLink>
                        </List>
                      </Collapse>
                    )}
                  </NavLink>
                </>
              ) : (
                <NavLink
                  key={index}
                  className={classes.sidebarItemLink}
                  activeClassName='active'
                  isActive={item.isActive}
                  to={item.url}
                >
                  <ListItem className={classes.sidebarItem}>
                    <ListItemIcon className={classes.sidebarItemIcon}>
                      {item.icon}
                    </ListItemIcon>
                    {!collapsed && (
                      <ListItemText
                        classes={{ primary: classes.sidebarItemTxt }}
                        primary={item.name}
                      />
                    )}
                  </ListItem>
                </NavLink>
              )}
            </div>
          ))}
        </List>
        <div className={classes.sidebarFooter}>
          <span className={classes.sidebarFooterTxt}>
            {/* Project supported by EIT */}
          </span>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(withStyles(SidebarStyles)(LeftSideBar));
