import {
  BaseCollection,
  GetLibraryCollectionsResponse,
  LibraryCollection,
} from 'mapfilm-api';
import { CollectionWithPoster } from 'models/ProductsModel';
import { api } from 'services/customAxios';
import { logFormData, objectToFormData } from './productService';

const getCollections = async (
  isSeller: boolean
): Promise<GetLibraryCollectionsResponse> => {
  try {
    const url = isSeller ? '/library/collection' : '/catalog/collection';
    const response = await api.get<GetLibraryCollectionsResponse>(url);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createCollection = async (
  collection: BaseCollection,
  poster: File | undefined
) => {
  try {
    let body: FormData | BaseCollection;
    if (poster) {
      body = objectToFormData(collection);
      body.append('file', poster);
      console.log('Form Data:');
      logFormData(body);
    } else {
      body = collection;
    }
    const response = await api.post('/library/collection', body);
    return response.data;
  } catch (error: any) {
    console.log(error.response);
    console.log('message: ' + error.message);
    throw error;
  }
};

const getCatalog = async () => {
  try {
    const response = await api.get('/catalog');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCollectionById = async (
  collectionId: string,
  isSeller: boolean
): Promise<LibraryCollection> => {
  try {
    const url = isSeller ? '/library/collection' : '/catalog/collection';
    const response = await api.get<LibraryCollection>(`${url}/${collectionId}`);
    console.log('resposta', response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateCollectionId = async (
  collection: CollectionWithPoster,
  poster: File | undefined,
  collectionId: string
) => {
  try {
    let body: FormData | CollectionWithPoster;
    if (poster) {
      body = objectToFormData(collection);
      body.append('file', poster);
      console.log('bodyy', body);
      console.log('Form Data:');
      logFormData(body);
    } else {
      body = collection;
    }
    const response = await api.post(
      `/library/collection/${collectionId}`,
      body
    );
    return response.data;
  } catch (error: any) {
    console.log(error.response);
    console.log('message: ' + error.message);
    throw error;
  }
};

export {
  createCollection,
  getCatalog,
  getCollectionById,
  getCollections,
  updateCollectionId,
};
