import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

import lineSplit from 'assets/images/lineSplit.svg';

import Swal from 'sweetalert2';

import { CollectionWithPoster, ProductWithPoster } from 'models/ProductsModel';
import Styles from 'styles/dashboard';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { createCollection } from 'services/collectionService';
import CollectionDetails from './addCollection/CollectionDetails';

interface OwnProps {
  classes: any;
  history: any;
  match: any;
}

interface State {
  collection: ProductWithPoster;
  edited: boolean;
}

const queryClient = new QueryClient();

const CreateProductPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CreateProductPage {...props} />
    </QueryClientProvider>
  );
};

const CreateProductPage: React.FC<OwnProps> = ({ classes }) => {
  const history = useHistory();

  const [collection, setCollection] = useState<CollectionWithPoster>({
    title: '',
    synopsis: '',
    products: [],
  });
  console.log(collection);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDropPoster = (acceptedFiles: File) => {
    setCollection((prevProduct) => ({
      ...prevProduct,
      poster: acceptedFiles,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value === '') {
      setCollection((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct[name as keyof typeof updatedProduct];
        return updatedProduct;
      });
    } else {
      setCollection((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleFilmSelect = (newValue: any[]) => {
    setCollection((prevCollection) => ({
      ...prevCollection,
      products: newValue,
    }));
  };

  const mutation = useMutation(
    async (data: { collection: CollectionWithPoster }) => {
      const { poster, ...collectionData } = data.collection;

      const collections: string[] = Array.isArray(data.collection.products)
        ? data.collection.products.map((product) =>
            typeof product === 'string'
              ? product
              : (product as { id: string }).id
          )
        : [];

      const updatedCollectionData = {
        ...collectionData,
        products: collections,
      };

      return createCollection(updatedCollectionData, poster);
    },
    {
      onSuccess: () => {
        history.push('/collections');
      },
      onError: (error: any) => {
        console.log('err', error);
        Swal.fire({
          icon: 'error',
          iconColor: PRIMARY_COLOR,
          text: error.message,
          showConfirmButton: false,
        });
      },
    }
  );

  return (
    <React.Fragment>
      <div>
        <Grid container className={classes.customGrid}>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            style={{ justifyContent: 'space-between' }}
          >
            <Typography
              className={classes.dashboardTitle}
              variant='h4'
              sx={{ fontWeight: 'bold' }}
            >
              Add Collection
            </Typography>

            <div className={classes.productBtnContainer}>
              <button
                className={`${classes.secondaryBtn} ${classes.btnSpaces}`}
                onClick={handleGoBack}
              >
                <span className={classes.optionDescription}>
                  Back to my collections
                </span>
              </button>
              <button
                className={classes.dashboardButton}
                type='submit'
                onClick={() => mutation.mutate({ collection })}
                disabled={!collection.title || collection.title === ''}
              >
                {mutation.isLoading ? (
                  <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                    <CircularProgress
                      sx={{
                        color: THIRD_COLOR,
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Box>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </Grid>
          <img src={lineSplit} className={classes.lineTop} />

          <CollectionDetails
            collection={collection}
            handleDrop={handleDropPoster}
            handleChange={handleChange}
            handleFilmSelect={handleFilmSelect}
            edit={false}
          />
          <img src={lineSplit} className={classes.lineBottom} />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(Styles as any)(CreateProductPageWrapper);
