import { withStyles } from '@material-ui/styles';
import React from 'react';

import { project } from 'project';

import AuthStyles from 'styles/auth';
import RegisterPageGeneral from './pages/RegisterPageGeneral';
import RegisterPageMFH from './pages/RegisterPageMFH';
import './RegisterPage.css';

interface Props {
  classes: any;
  history: any;
}

const RegisterPage: React.FC<Props> = ({ classes, history }) => {
  return (
    <>
      {project === 'mfh' ? (
        <RegisterPageMFH classes={classes} history={history} />
      ) : (
        <RegisterPageGeneral classes={classes} history={history} />
      )}
    </>
  );
};

export default withStyles(AuthStyles as any)(RegisterPage);
