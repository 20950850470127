import { withStyles } from '@material-ui/styles';
import Loading from 'components/loading/Loading';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import combineStyles from 'utils/combineStyles';

import { Button } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';

import { getAllProducts } from 'services/productService';

import { LibraryProduct } from 'mapfilm-api';
import { hasLicense, isBuyer, isSeller, isStudent } from 'services/authService';
import DashboardStyles from 'styles/dashboard';
import ProductsStyles from 'styles/movies';

import FiltersSideBar from 'components/filtersSideBar/filtersSideBar';

import TermsUsageModal from 'components/modal/TermsUsageModal';
import { project } from 'project';
import CollectionsHeader from './LibraryHeader';

import NoAccessUser from './NoAccessUser';

import { TypeContent } from 'models/enums';
import { useParams } from 'react-router';
import { getCollectionById, getCollections } from 'services/collectionService';
import ProductList from './ProductList';
import ViewChanger from './ViewChanger';

import { Grid } from '@mui/material';
import CollapsableDescription from 'components/collapsableDescription/collapsableDescription';
import { NavLink } from 'react-router-dom';

interface OwnProps {
  classes?: any;
  history: any;
  match: any;
  typeContent: any;
}

const queryClient = new QueryClient();

const LibraryPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <LibraryPage {...props} />
    </QueryClientProvider>
  );
};

const LibraryPage: React.FC<OwnProps> = ({ classes, typeContent }) => {
  const [products, setProducts] = useState([] as LibraryProduct[]);
  const [loading, setLoading] = useState(true as boolean);
  const [filteredProducts, setFilteredProducts] = useState(
    [] as LibraryProduct[]
  );
  const [searchQuery, setSearchQuery] = useState('' as string);
  const [countriesList, setCountriesList] = useState<string[]>([]);
  const currentYear = new Date().getFullYear();

  /////////////////////////////////////////////////
  const [isSimpleView, setIsSimpleView] = useState(false);

  const handleToggleView = (
    event: React.MouseEvent<HTMLElement>,
    newView: boolean | null
  ) => {
    if (newView !== null) {
      setIsSimpleView(newView);
    }
  };
  ///////////////////////////////////////////////////

  const isMFHProject = project === 'mfh';
  const {
    data: hasLicenseSigned,
    isLoading,
    error,
  } = useQuery(['licenseStatus'], hasLicense, {
    enabled: isMFHProject,
  });

  const defaultValuesPlaceholder = {
    rightsOwner: 'placeholder',
    regions: [],
    year: [0, 0],
    duration: [0, 0],
    themes: [] as string[],
  };
  const [filters, setFilters] = useState(defaultValuesPlaceholder);
  const [defaultValues, setDefaultValues] = useState(defaultValuesPlaceholder);
  const [themesList, setThemesList] = useState<string[]>([]);
  const [rightsOwners, setRightsOwners] = useState([] as string[]);
  const [collectionInfo, setCollectionInfo] = useState<{
    id: string;
    name: string;
    description: string;
  }>({
    id: '',
    name: '',
    description: '',
  });

  const [displayedProducts, setDisplayedProducts] = useState<LibraryProduct[]>(
    []
  );

  const { id: productId } = useParams<{ id: string }>();

  const [itemsToShow, setItemsToShow] = useState(10);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    //console.log(filters);
  }, [filters]);

  const fetchData = () =>
    typeContent === TypeContent.FILMS
      ? getAllProducts(isSeller())
      : typeContent === TypeContent.COLLECTIONFILMS && productId
        ? getCollectionById(productId, isSeller())
        : getCollections(isSeller());

  const {
    data: dataProducts,
    isLoading: loadingProducts,
    isError: errorProducts,
  } = useQuery([typeContent, isSeller()], fetchData, {
    onSuccess: (data) => {
      if (
        typeContent === TypeContent.FILMS ||
        typeContent === TypeContent.COLLECTIONFILMS
      ) {
        if (typeContent === TypeContent.COLLECTIONFILMS) {
          setCollectionInfo({
            id: data.id,
            name: data.name,
            description: data.description,
          });
        }
        setLoading(false);
        const products = data.products;
        setProducts(products);
        setFilteredProducts(products);
        setDisplayedProducts(products.slice(0, itemsToShow));
        setCountriesList(data.locations);
        setThemesList(data.genres);
        const uniqueRightsOwners = Array.from(
          new Set(
            products
              .map((product: any) => product?.owner)
              .filter((owner: any) => owner !== undefined)
          )
        );
        setRightsOwners(uniqueRightsOwners as string[]);

        const validDurations = products
          .map((product: LibraryProduct) => Number(product.duration))
          .filter((duration: any) => !isNaN(duration) && duration > 0);

        const validYears = products
          .map((product: LibraryProduct) => Number(product.year))
          .filter((year: any) => !isNaN(year) && year > 0);

        const minDuration =
          validDurations.length > 0 ? Math.min(...validDurations) : 0;
        const maxDuration =
          validDurations.length > 0 ? Math.max(...validDurations) : 0;

        const minYear = validYears.length > 0 ? Math.min(...validYears) : 0;
        const maxYear = validYears.length > 0 ? Math.max(...validYears) : 0;

        const filtersDefaultValues = {
          rightsOwner: 'placeholder',
          regions: [],
          year: [minYear, maxYear],
          duration: [minDuration, maxDuration],
          themes: [],
        };
        setDefaultValues(filtersDefaultValues);
        setFilters(filtersDefaultValues);
      } else {
        setLoading(false);
        const products = data.collections;
        setProducts(products);
        setFilteredProducts(products);
        setDisplayedProducts(products.slice(0, itemsToShow));
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    // console.log(dataProducts);
  }, [dataProducts, loadingProducts, displayedProducts]);

  useEffect(() => {
    // console.log(filteredProducts);
  }, [filteredProducts]);

  useEffect(() => {
    if (searchQuery) {
      const preFilteredProducts = products.filter(
        (product: LibraryProduct) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.synopsis?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProducts(preFilteredProducts);
    } else if (JSON.stringify(filters) !== JSON.stringify(defaultValues)) {
      let preFilteredProducts = products;
      if (
        filters.duration[0] != defaultValues.duration[0] ||
        filters.duration[1] != defaultValues.duration[1]
      ) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return (
              product.duration &&
              product.duration <= filters.duration[1] &&
              product.duration >= filters.duration[0]
            );
          }
        );
      }
      if (
        filters.year[0] != defaultValues.year[0] ||
        filters.year[1] != defaultValues.year[1]
      ) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return (
              product.year &&
              product.year <= filters.year[1] &&
              product.year >= filters.year[0]
            );
          }
        );
      }
      if (filters.themes.length > 0) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return product.genres?.some(
              (genre: any) =>
                filters.themes.find((theme: any) => theme === genre) !==
                undefined
            );
          }
        );
      }
      if (filters.regions.length > 0) {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => {
            return product.regions?.some(
              (region: any) =>
                filters.regions.find((op: any) => op === region) !== undefined
            );
          }
        );
      }

      if (filters.rightsOwner !== 'placeholder') {
        preFilteredProducts = preFilteredProducts.filter(
          (product: LibraryProduct) => product.owner === filters.rightsOwner
        );
      }
      setFilteredProducts(preFilteredProducts);
    } else {
      setFilteredProducts(products);
    }
  }, [searchQuery, filters, defaultValues]);

  useEffect(() => {
    setDisplayedProducts(filteredProducts.slice(0, itemsToShow));
  }, [filteredProducts, itemsToShow]);

  const loadMoreProducts = useCallback(() => {
    setItemsToShow((prev) => prev + 10);
  }, []);

  const lastProductRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreProducts();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, loadMoreProducts]
  );

  const CollectionsLoading = (
    <>
      {/* <CollectionsHeader /> */}
      <Loading />
    </>
  );

  /******** For terms of use modal */
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasAgreed = localStorage.getItem('hasAgreedToTerms');
    if (!hasAgreed) {
      setIsModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('hasAgreedToTerms', 'true');

    setIsModalOpen(false);
  };
  /*************************** */

  return (
    <Suspense fallback={CollectionsLoading}>
      {isStudent() &&
      (hasLicenseSigned == null || hasLicenseSigned === false) &&
      project === 'mfh' ? (
        <NoAccessUser classes={classes} />
      ) : (
        <>
          {isBuyer() && project === 'mfh' && (
            <TermsUsageModal
              open={isModalOpen}
              handleClose={handleCloseModal}
            />
          )}
          {loading || loadingProducts ? (
            <Loading />
          ) : (
            <>
              <CollectionsHeader
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                productsLength={filteredProducts?.length}
                typeContent={typeContent}
                collectionInfo={collectionInfo}
              />
              <div className={classes.headerContainerCollection}>
                <ViewChanger
                  isSimpleView={isSimpleView}
                  handleToggleView={handleToggleView}
                  className={classes.toogleView}
                />
                {isSeller() && typeContent === TypeContent.COLLECTIONFILMS && (
                  <NavLink
                    className={classes.dashLinkBtn}
                    style={{ marginRight: '0' }}
                    to={`/collections/${collectionInfo.id}/edit`}
                  >
                    <Button
                      className={classes.dashboardButton}
                      style={{ margin: '0px 0px 20px 10px' }}
                      variant='outlined'
                    >
                      <span className='btn-txt'>Edit Collection</span>
                      <EditIcon className={classes.btnIcon} />
                    </Button>
                  </NavLink>
                )}
              </div>
              {typeContent === TypeContent.COLLECTIONFILMS && (
                <CollapsableDescription
                  description={collectionInfo.description}
                  classes={classes}
                />
              )}

              <Grid container>
                <Grid
                  item
                  xs={
                    typeContent === TypeContent.FILMS ||
                    typeContent === TypeContent.COLLECTIONFILMS
                      ? 9
                      : 12
                  }
                >
                  <ProductList
                    products={displayedProducts}
                    isSimpleView={isSimpleView}
                    themesList={themesList}
                    lastProductRef={lastProductRef}
                    classes={classes}
                    typeContent={typeContent}
                  />
                </Grid>
                {typeContent === TypeContent.FILMS ||
                typeContent === TypeContent.COLLECTIONFILMS ? (
                  <Grid item xs={3}>
                    <FiltersSideBar
                      countriesList={countriesList as string[]}
                      filters={filters}
                      setFilters={setFilters}
                      defaultValues={[defaultValues, setDefaultValues]}
                      themesList={themesList}
                      setThemesList={setThemesList}
                      products={products as LibraryProduct[]}
                      rightsOwners={rightsOwners}
                      currentYear={currentYear}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </>
          )}
        </>
      )}
    </Suspense>
  );
};

const combinedStyles = combineStyles(DashboardStyles, ProductsStyles);

export default withStyles(combinedStyles as any)(LibraryPageWrapper);
