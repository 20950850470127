import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import React, { useEffect, useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from 'react-query';
import { NavLink, useHistory, useParams } from 'react-router-dom';

import { connect } from 'react-redux';

import { Box } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';

import Loading from 'components/loading/Loading';
import { UserModel } from 'models';
import ProductInfo from 'pages/product/ProductInfo';
import VideoPopup from 'pages/product/ProductVideo';
import { RootState } from 'redux/store';
import {
  downloadFilm,
  getProduct,
  requestDownload,
} from 'services/productService';
import { getUser } from 'services/userService';
import combineStyles from 'utils/combineStyles';

import Swal from 'sweetalert2';

import { isBuyer, isSeller } from 'services/authService';
import { getBespokeLicense } from 'services/educationalLicenseService';

import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';

import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropUpIcon from '@mui/icons-material/KeyboardArrowUp';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { LibraryProduct } from 'mapfilm-api';
import {
  FOURTH_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  THIRD_COLOR,
} from 'styles/colors';
import DashboardStyles from 'styles/dashboard';
// import DownloadModal from './DownloadModal';
import MovieStyles from './PStyles';

import { getIDsDownload } from 'services/productService';

import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { Menu, MenuItem } from '@mui/material';

import { project } from 'project';
import DownloadsCounter from './DownloadsCounter';

const LICENSE_SELLER_ERROR = 'License template already exists.';
const NO_ORG_ERROR =
  'You are not associated to any organization! Please insert your organization in the Profile Page.';
const LICENSE_BUYER_ERROR = 'No license was created for this movie.';

const queryClient = new QueryClient();

interface OwnProps {
  classes: any;
}

interface StateProps {
  user: UserModel;
}

type Props = StateProps & OwnProps;

const ProductPageWrapper: React.FC<Props> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ProductPage {...props} />
    </QueryClientProvider>
  );
};

const ProductPage: React.FC<Props> = ({ classes, user }) => {
  const [product, setProduct] = useState<LibraryProduct>({
    id: '',
    title: '',
    name: '',
  });
  const [loading, setLoading] = useState(true);
  const [licenseError, setLicenseError] = useState('');
  const [orgError, setOrgError] = useState('');
  const [templateExists, setTemplateExists] = useState(false);
  const [hasOrg, setHasOrg] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [mediaId, setMediaId] = useState('');
  const history = useHistory();
  const { id: productId } = useParams<{ id: string }>();
  const [selectedVersionId, setSelectedVersionId] = useState(null);

  // const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadedId, setDownloadedId] = useState<string | null>(null);

  const { data: productData, isLoading: productLoading } = useQuery(
    ['product', productId],
    () => getProduct(productId, isBuyer()),
    {
      onSuccess: (data) => setProduct(data),
    }
  );
  const { data: userInfo, isLoading: userLoading } = useQuery('user', getUser, {
    onSuccess: (data) => setHasOrg(data.org ? true : false),
  });

  const { data: licenseData, isLoading: licenseLoading } = useQuery(
    ['bespokeLicense', productId],
    () => getBespokeLicense(productId),
    {
      enabled: project !== 'mfh',
      onSuccess: (data) => setTemplateExists(data),
    }
  );

  const { data: usrDownloads, isLoading: usrDownloadsLoading } = useQuery(
    ['usrDownloads'],
    () => {
      if (isBuyer()) {
        return getIDsDownload();
      }
      return [];
    },
    {
      onError: (error) => console.log(error),
      enabled: isBuyer(),
    }
  );

  let downloadedIds: string[] = [];

  if (usrDownloads) {
    downloadedIds = usrDownloads.map((product: any) => product.id);
  }

  const isProductDownloaded = (productId: string) => {
    return downloadedIds.includes(productId);
  };

  const downloadMutation = useMutation(() => requestDownload(productId), {
    onError: (error: any) => {
      Swal.fire({
        icon: 'error',
        iconColor: PRIMARY_COLOR,
        text: error.message,
        showConfirmButton: false,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['downloadCount']);
      queryClient.invalidateQueries(['usrDownloads']);
    },
  });

  const downloadFilesMutation = useMutation(
    ({ versionId, versionName }: { versionId: string; versionName: string }) =>
      downloadFilm(productId, versionId),
    {
      onSuccess: (data, { versionName }) => {
        // console.log('file', data);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${versionName}.mp4`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      },
      onError: (error: any) => {
        console.error('Failed to download product', error);
      },
    }
  );

  const handleDownload = ({
    versionId,
    versionName,
  }: {
    versionId: string;
    versionName: string;
  }) => {
    setDownloadedId(versionId);
    downloadFilesMutation.mutate({ versionId, versionName });
  };

  const handleClick = (event: any) => {
    if (isProductDownloaded(product.id)) {
      setAnchorEl(event.currentTarget);
    } else {
      downloadMutation.mutate();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (productData && userInfo) {
      if (isSeller() && licenseData && project !== 'mfh') {
        setLicenseError(LICENSE_SELLER_ERROR);
      } else if (isBuyer() && !licenseData && project !== 'mfh') {
        setLicenseError(LICENSE_BUYER_ERROR);
      }

      if (!userInfo.org) {
        setOrgError(NO_ORG_ERROR);
      }

      setLoading(false);
    }
  }, [productData, userInfo, licenseData]);

  if (
    loading ||
    productLoading ||
    userLoading ||
    (project !== 'mfh' && licenseLoading)
  ) {
    return <Loading />;
  }

  if (!product) {
    return (
      <div className={classes.productNotFound}>
        <Typography variant='h1' className={classes.productNotFoundTitle}>
          Oops...
        </Typography>
        <Typography variant='h2' className={classes.productNotFoundTxt}>
          Film was not found!
        </Typography>
      </div>
    );
  }
  if (!product?.title) {
    return (
      <div className={classes.productNotFound}>
        <Loading />
      </div>
    );
  }

  const showError = licenseError || orgError;

  return (
    <div>
      <div className={classes.headerContainer}>
        <Typography variant='h5' className={classes.dashboardSubtitle}>
          {product.title}
        </Typography>
        {isSeller() && (
          <NavLink
            className={classes.dashLinkBtn}
            to={`/movies/${product.id}/edit`}
          >
            <Button className={classes.dashboardButton} variant='outlined'>
              Edit Film
              <EditIcon className={classes.iconsAlignment} />
            </Button>
          </NavLink>
        )}

        {isBuyer() &&
          product.versions &&
          product.versions.length > 0 &&
          project === 'mfh' && (
            <>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  className={classes.dashboardButton}
                  onClick={(e) => handleClick(e)}
                  disabled={downloadMutation.isLoading}
                  variant='outlined'
                >
                  <p style={{ width: '120px' }}>Download Film</p>
                  {downloadMutation.isLoading ? (
                    <Box
                      sx={{
                        display: 'inline',
                        paddingLeft: '10px',
                        paddingTop: '8px',
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: THIRD_COLOR,
                          width: '20px !important',
                          height: '20px !important',
                        }}
                      />
                    </Box>
                  ) : (
                    <>
                      {isProductDownloaded(product.id) ? (
                        anchorEl === null ? (
                          <ArrowDropDownIcon
                            className={classes.iconsAlignment}
                          />
                        ) : (
                          <ArrowDropUpIcon className={classes.iconsAlignment} />
                        )
                      ) : (
                        <DownloadIcon className={classes.iconsAlignment} />
                      )}
                    </>
                  )}
                </Button>
                {!downloadMutation.isLoading && !downloadMutation.isError && (
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className={classes.menuStyle}
                  >
                    {product.versions.map((version) => (
                      <MenuItem
                        key={version._id}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedVersionId(version._id);
                          handleDownload({
                            versionId: version._id,
                            versionName: version.title,
                          });
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: THIRD_COLOR,
                            color: SECONDARY_COLOR,
                          },
                          color:
                            selectedVersionId === version._id
                              ? `${SECONDARY_COLOR}`
                              : `${THIRD_COLOR}`,
                          backgroundColor:
                            selectedVersionId === version._id
                              ? `${THIRD_COLOR}`
                              : `${FOURTH_COLOR}`,
                          height: '40px',
                          minHeight: 'auto',
                        }}
                      >
                        {version.name}
                        {downloadFilesMutation.isLoading &&
                        downloadedId === version._id ? (
                          <Box
                            sx={{
                              display: 'inline',
                              marginTop: '8px',
                              marginLeft: '10px',
                            }}
                          >
                            <CircularProgress
                              sx={{
                                color: SECONDARY_COLOR,
                                width: '20px !important',
                                height: '20px !important',
                              }}
                            />
                          </Box>
                        ) : downloadFilesMutation.isSuccess &&
                          downloadedId === version._id ? (
                          <FileDownloadDoneIcon
                            className={classes.iconsAlignment}
                          />
                        ) : (
                          <DownloadIcon className={classes.iconsAlignment} />
                        )}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
                <DownloadsCounter />
              </div>
            </>
          )}
      </div>
      <Typography
        className={classes.licenseError}
        component='h1'
        variant='h6'
        style={{ color: 'red', display: showError ? 'block' : 'none' }}
      >
        {orgError && (
          <span className={classes.errorMessage}>
            <ArrowForwardIosIcon /> {orgError}
          </span>
        )}
        {licenseError && (
          <span className={classes.errorMessage}>
            <ArrowForwardIosIcon /> {licenseError}
          </span>
        )}
      </Typography>

      <ProductInfo
        product={product}
        openVideoPopup={setDialog}
        setVideoInfo={(id: string, title: string) => {
          setMediaId(id);
          setDialogTitle(title);
        }}
      />

      <VideoPopup
        openPopup={dialog}
        setOpenPopup={setDialog}
        dialogTitle={project === 'mfh' ? product.title : dialogTitle}
        id={mediaId}
      />

      <div className={classes.productOptions}>
        {/* <NavLink className={classes.dashLinkBtn} to={'/catalogue'}>
          <Button className={classes.secondaryBtn} variant='outlined'>
            {isSeller() ? 'Back to My Films' : 'Back to Films'}
          </Button>
        </NavLink> */}
        <Button
          className={classes.secondaryBtn}
          onClick={() => history.goBack()}
          variant='outlined'
        >
          {isSeller() ? 'Back' : 'Back'}
        </Button>

        {isSeller() && project !== 'mfh' ? (
          <>
            <Button
              className={classes.dashboardButton}
              variant='outlined'
              disabled={templateExists || !hasOrg}
            >
              {templateExists || !hasOrg ? (
                'Create License'
              ) : (
                <NavLink
                  className={classes.dashLinkBtn}
                  to={'/movies/' + product.id + '/createLicense'}
                >
                  Create License
                </NavLink>
              )}
            </Button>
          </>
        ) : (
          ''
        )}

        {isBuyer() && project !== 'mfh' ? (
          <>
            <Button
              className={classes.dashboardButton}
              variant='outlined'
              disabled={!templateExists || !hasOrg}
            >
              {!templateExists || !hasOrg ? (
                'Customize License'
              ) : (
                <NavLink
                  className={classes.dashLinkBtn}
                  to={'/movies/' + product.id + '/customizeLicense'}
                >
                  Customize License
                </NavLink>
              )}
            </Button>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const combinedStyles = combineStyles(DashboardStyles, MovieStyles);

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(withStyles(combinedStyles)(ProductPageWrapper));
