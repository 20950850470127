import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Link as NavLink } from 'react-router-dom';

// import AuthSplash from 'components/authSplash/AuthSplash';
// import PhoneInput from 'components/phoneInput/PhoneInput';
// import { getInstitutions } from 'services/userService';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';

import { normalizeMessage } from 'utils/utils';

import { register } from 'services/authService';
import {
  validateConfirmPassword,
  validateForm,
  validateInput,
} from 'utils/validation';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Swal from 'sweetalert2';

import AuthStyles from 'styles/auth';
import '../RegisterPage.css';

import TutorForm from '../forms/RegisterTutorForm';
import StudentForm from '../forms/RegisterUserForm';

// import PartnersFooter from 'components/footer/partnersFooter';

import { RegisterUserModel } from 'mapfilm-api';
// import { project } from 'project';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';

interface OwnProps {
  classes: any;
  history: any;
}

interface State {
  form: RegisterUserModel;
  checkTerms: boolean;
  errors: {
    firstNameError: string;
    lastNameError: string;
    usernameError: string;
    emailError: string;
    passwordError: string;
    confirmPasswordError: string;
    //institutionError: string,
    checkTermsError: string;
  };
  showPassword: boolean;
  showConfirmPassword: boolean;
  formErrorMessage: string;
}

const queryClient = new QueryClient();

const RegisterPageMFHWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RegisterPageMFH {...props} />
    </QueryClientProvider>
  );
};

const RegisterPageMFH: React.FC<OwnProps> = ({ classes, history }) => {
  const [form, setForm] = useState<RegisterUserModel>({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    userRole: 'user',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [checkTerms, setCheckTerms] = useState(false);
  const [errors, setErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    usernameError: '',
    emailError: '',
    passwordError: '',
    confirmPasswordError: '',
    checkTermsError: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setInputStateError(`${name}Error`, '');
  };

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckTerms(event.target.checked);
    setInputStateError(`${event.target.name}Error`, '');
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setConfirmPassword(value);
    setInputStateError(`${name}Error`, '');
  };

  const inputStyle = (error: string) => {
    const getelement = (errors: any, error: string) => {
      return errors[error];
    };
    const errorValue = getelement(errors, error);
    return errorValue === '' || errorValue === 'undefined'
      ? classes.inputTextfield
      : classes.inputTextfieldError;
  };

  const registerPageMutation = useMutation(
    (registerUserModel: RegisterUserModel) => register(registerUserModel),
    {
      onError: (error: any) => {
        if (error === 'No organization found') {
          if (form.userRole === 'user') {
            Swal.fire({
              //position: 'top-end',
              icon: 'error',
              iconColor: PRIMARY_COLOR,
              text: 'Your email domain is not registered to an institution. Please contact your institution to register in the platform',
              showConfirmButton: false,
            });
          }
        } else {
          setFormErrorMessage(error);
        }
      },
      onSuccess: (data) => {
        // console.log('info', data);
        if (form.userRole === 'user') {
          Swal.fire({
            //position: 'top-end',
            icon: 'success',
            iconColor: SECONDARY_COLOR,
            text: 'Registration successful. An email will be sent to your address to confirm your account.',
            showConfirmButton: false,
          }).then(() => {
            window.location.href = '/auth/login';
          });
        } else {
          if (data.institutionId !== null) {
            Swal.fire({
              // position: 'top-end',
              icon: 'warning',
              iconColor: SECONDARY_COLOR,
              text: 'To complete your registration request, please confirm your account via the email sent to your address. After your registration request is approved, an email will be sent to your address. :)',
              showConfirmButton: false,
            }).then(() => {
              window.location.href = '/auth/login';
            });
          } else {
            Swal.fire({
              // position: 'top-end',
              icon: 'warning',
              iconColor: PRIMARY_COLOR,
              html: 'Your email domain is not registered to an institution. <a id="redirectLink" href="#">Click here</a> to request the addition of your institution. You need to be associated with an institution to enter on the platform. To complete your registration request, please confirm your account via the email sent to your address ',
              showConfirmButton: false,
              willOpen: () => {
                const redirectLink = document.getElementById('redirectLink');
                if (redirectLink) {
                  redirectLink.addEventListener('click', function (event) {
                    event.preventDefault();
                    window.location.href = '/auth/institution';
                  });
                }
              },
            });
          }
        }
      },
    }
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // validate form
    const validation = validateForm(form, confirmPassword);
    let formErrorMessage = '';

    if (!checkTerms) {
      formErrorMessage =
        'You have not agreed to the terms and conditions of the website.';
      setFormErrorMessage(formErrorMessage);
      return;
    }

    if (validation === ' ') {
      const registerUserModel: RegisterUserModel = {
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        password: form.password,
        userRole: form.userRole,
        username: form.username,
      };
      if (registerUserModel.userRole === 'staff') {
        if (form.info === '' || form.info === undefined) {
          formErrorMessage = 'Please enter information about yourself';
          setFormErrorMessage(formErrorMessage);
          return;
        }
        registerUserModel.info = form.info;
      }
      // console.log(registerUserModel);
      registerPageMutation.mutate(registerUserModel);
    } else formErrorMessage = normalizeMessage(validation) + ' is invalid!';

    setFormErrorMessage(formErrorMessage);
  };

  const setInputStateError = (errorName: string, errorValue: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorName]: errorValue,
    }));
  };

  const onBlurHandle = async (name: string) => {
    // Validate
    const errorName = `${name}Error`;
    let errorValue = '';
    const getelement = (form: any, name: string) => {
      return form[name];
    };

    const inputValue = getelement(form, name);
    // console.log('inputVal', inputValue);

    if (!(inputValue === '' || typeof inputValue === undefined)) {
      if (name === 'confirmPassword') {
        errorValue = validateConfirmPassword(form.password, confirmPassword);
      } else {
        errorValue = validateInput(name, inputValue);
      }
    }

    setInputStateError(errorName, errorValue);
  };

  const handleChangeRoleType = (newValue: 'user' | 'staff') => {
    setForm((prevForm) => ({
      ...prevForm,
      userRole: newValue,
    }));
  };

  return (
    <>
      <div className={classes.authFormContainer}>
        <div className={classes.authFormBox}>
          <div className={classes.titleContainer}>
            <Typography
              component='h1'
              variant='h5'
              classes={{ root: classes.registerTitle }}
            >
              <span className={classes.changeColor}>Create</span> an account
            </Typography>
            <p className={classes.aboveSubtitle}>
              to have the complete solution in your hands
            </p>
          </div>
          <Typography
            className={classes.authError}
            component='h1'
            variant='h6'
            style={{ color: 'red' }}
          >
            {formErrorMessage}
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className={classes.authOptions}>
              <ToggleButtonGroup
                className={classes.toogleButton}
                exclusive
                aria-label='Platform'
              >
                <ToggleButton
                  className={`${classes.toggleButtonHalf} ${
                    form.userRole === 'user'
                      ? classes.selectedToggle
                      : classes.notSelectedToggle
                  }`}
                  onClick={() => handleChangeRoleType('user')}
                  value='user'
                >
                  Students
                </ToggleButton>
                <ToggleButton
                  className={`${classes.toggleButtonHalf} ${
                    form.userRole === 'staff'
                      ? classes.selectedToggle
                      : classes.notSelectedToggle
                  }`}
                  onClick={() => handleChangeRoleType('staff')}
                  value='staff'
                >
                  Tutors
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <Grid className={classes.formContainer} container spacing={3}>
              {form.userRole === 'user' && (
                <StudentForm
                  classes={classes}
                  form={form}
                  inputStyle={inputStyle}
                  handleChange={handleChange}
                  onBlurHandle={onBlurHandle}
                  errors={errors}
                  confirmPassword={confirmPassword}
                  showPassword={showPassword}
                  showConfirmPassword={showConfirmPassword}
                  handlePasswordChange={handlePasswordChange}
                  handleClickShowPassword={handleClickShowPassword}
                  handleClickShowConfirmPassword={
                    handleClickShowConfirmPassword
                  }
                />
              )}
              {form.userRole === 'staff' && (
                <TutorForm
                  classes={classes}
                  form={form}
                  inputStyle={inputStyle}
                  handleChange={handleChange}
                  onBlurHandle={onBlurHandle}
                  errors={errors}
                  confirmPassword={confirmPassword}
                  showPassword={showPassword}
                  showConfirmPassword={showConfirmPassword}
                  handlePasswordChange={handlePasswordChange}
                  handleClickShowPassword={handleClickShowPassword}
                  handleClickShowConfirmPassword={
                    handleClickShowConfirmPassword
                  }
                />
              )}

              <Grid
                className={classes.disclaimerContainer}
                item
                xs={12}
                sm={12}
              >
                <FormControlLabel
                  className={classes.termsCheckbox}
                  label={
                    <>
                      I agree with the{' '}
                      <Link
                        className={classes.authLink}
                        component={NavLink}
                        to='/auth/termsAndConditions'
                      >
                        terms and conditions
                      </Link>{' '}
                      of the MAP Marketplace.
                    </>
                  }
                  control={
                    <Checkbox
                      checked={checkTerms}
                      onChange={handleCheckedChange}
                      name='checkTerms'
                      color='primary'
                    />
                  }
                />
              </Grid>
            </Grid>
            <div className={classes.registerOptions}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='default'
                className={classes.submit}
                disabled={registerPageMutation.isLoading}
                onClick={(e: any) => handleSubmit(e)}
              >
                {registerPageMutation.isLoading ? (
                  <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                    <CircularProgress
                      sx={{
                        color: PRIMARY_COLOR,
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Box>
                ) : (
                  'Sign up'
                )}
              </Button>

              <div className={classes.loginLinkContainer}>
                <Typography className={classes.authTxt}>
                  {'Already have an account?'}
                </Typography>
                <Link
                  className={classes.authLink}
                  component={NavLink}
                  to='/auth/login'
                >
                  Sign in
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* {project === 'mfh' && <PartnersFooter />} */}
    </>
  );
};

export default withStyles(AuthStyles as any)(RegisterPageMFHWrapper);
