import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

import lineSplit from 'assets/images/lineSplit.svg';

import Swal from 'sweetalert2';

import {
  EditCollectionWithPoster,
  ProductWithPoster,
} from 'models/ProductsModel';
import Styles from 'styles/dashboard';

import Loading from 'components/loading/Loading';
import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { isSeller } from 'services/authService';
import {
  getCollectionById,
  updateCollectionId,
} from 'services/collectionService';
import { mapCollectionFields } from 'utils/utils';
import CollectionDetails from './addCollection/CollectionDetails';

interface OwnProps {
  classes: any;
  history: any;
  match: any;
  edited: boolean;
  collection?: ProductWithPoster;
}

const queryClient = new QueryClient();

const EditProductPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <EditProductPage {...props} />
    </QueryClientProvider>
  );
};

const EditProductPage: React.FC<OwnProps> = ({ classes, match }) => {
  const history = useHistory();

  const [collection, setCollection] = useState<EditCollectionWithPoster>({
    id: '',
    name: '',
    description: '',
    products: [],
  });

  const getCollectionMutation = useMutation(
    async (productId: string): Promise<EditCollectionWithPoster> => {
      const product = await getCollectionById(productId, isSeller());
      return product;
    },
    {
      onSuccess: (data: EditCollectionWithPoster) => {
        setCollection(data);
      },
      onError: (error: any) => {
        console.error('Error fetching product:', error);
      },
    }
  );

  useEffect(() => {
    const productId = match.params.id;
    getCollectionMutation.mutate(productId);
  }, [match.params.id]);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDropPoster = (acceptedFiles: File) => {
    setCollection((prevCollection) => ({
      ...prevCollection,
      poster: acceptedFiles,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCollection((prevCollection) => ({
      ...prevCollection,
      [name]: value,
    }));
  };

  const handleFilmSelect = (newValue: any[]) => {
    setCollection((prevCollection) => ({
      ...prevCollection,
      products: newValue,
    }));
  };

  console.log('info', collection);
  const mutation = useMutation(
    async (data: { collection: EditCollectionWithPoster }) => {
      console.log('data collection', data.collection);
      const { poster, regions, genres, ...collectionData } = data.collection;

      const updatedCollectionData = mapCollectionFields(collectionData);

      return updateCollectionId(
        updatedCollectionData,
        poster,
        data.collection.id
      );
    },
    {
      onSuccess: () => {
        history.push('/collections');
      },
      onError: (error: any) => {
        console.log('err', error);
        Swal.fire({
          icon: 'error',
          iconColor: PRIMARY_COLOR,
          text: error.message,
          showConfirmButton: false,
        });
      },
    }
  );

  return (
    <React.Fragment>
      <div>
        <Grid container className={classes.customGrid}>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            style={{ justifyContent: 'space-between' }}
          >
            <Typography
              className={classes.dashboardTitle}
              variant='h4'
              sx={{ fontWeight: 'bold' }}
            >
              Edit Collection
            </Typography>

            <div className={classes.productBtnContainer}>
              <button
                className={`${classes.secondaryBtn} ${classes.btnSpaces}`}
                onClick={handleGoBack}
              >
                <span className={classes.optionDescription}>
                  Back to my collection
                </span>
              </button>
              <button
                className={classes.dashboardButton}
                type='submit'
                onClick={() => mutation.mutate({ collection })}
                disabled={!collection.name || collection.name === ''}
              >
                {mutation.isLoading ? (
                  <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                    <CircularProgress
                      sx={{
                        color: THIRD_COLOR,
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Box>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </Grid>
          <img src={lineSplit} className={classes.lineTop} />

          {getCollectionMutation.isLoading ? (
            <Loading />
          ) : (
            <CollectionDetails
              collection={collection}
              handleDrop={handleDropPoster}
              handleChange={handleChange}
              handleFilmSelect={handleFilmSelect}
              edit={true}
            />
          )}

          <img src={lineSplit} className={classes.lineBottom} />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(Styles as any)(EditProductPageWrapper);
