import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import UploadIcon from 'assets/icons/uploadIcon.svg';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Styles from './DropzoneStyles';

interface SubtitleDropzoneProps {
  propFile: any;
  setPropFile: (acceptedFile: File) => void;
  classes?: any;
}

const SubtitleDropzone: React.FC<SubtitleDropzoneProps> = ({
  propFile,
  setPropFile,
  classes,
}) => {
  const [fileName, setFileName] = useState<string | null>(null);

  useEffect(() => {
    if (propFile) {
      setFileName(propFile.name);
    }
  }, [propFile]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/vtt': ['.vtt'],
      'text/srt': ['.srt'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const selectedFile = acceptedFiles[0];
        setFileName(selectedFile.name);
        setPropFile(selectedFile);
      }
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {fileName ? (
          <div {...getRootProps()} className={classes.labelsContainer}>
            <input {...getInputProps()} />
            <div className={classes.pContainer}>
              <p className={classes.fileName}>{fileName}</p>
            </div>
          </div>
        ) : (
          <div {...getRootProps()} className={classes.labelsContainer}>
            <input {...getInputProps()} />
            <div className={classes.pContainer}>
              <img src={UploadIcon} alt='Upload Icon' />
              <p className={classes.pStyle}>.srt or .vtt</p>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(Styles as any)(SubtitleDropzone);
