import { FormLabel, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import Styles from 'styles/dashboard';

import { BaseProduct } from 'mapfilm-api';
import { ProductVersionForm } from 'models/ProductsModel';

import SubtitlesForm from './SubtitlesForm';

interface Props {
  product: BaseProduct;
  classes: any;

  handleAddForm: any;
  handleRemoveForm: any;
  handleInputChange: any;
  handleDropChange: any;
  handleFilmVersionAdd: any;
  handleFilmStatusQuery: (
    status: {
      index: number;
      status: string;
      id: string;
    }[]
  ) => void;
  productMedia: any;
}

const AddSubtitles: React.FC<Props> = ({
  product,
  classes,
  handleAddForm,
  handleRemoveForm,
  handleInputChange,
  handleDropChange,
  handleFilmVersionAdd,
  handleFilmStatusQuery,
  productMedia,
}) => {
  return (
    <React.Fragment>
      <div>
        <Grid container>
          <Grid item container sm={12} alignItems='flex-start'>
            <Grid item container spacing={4}>
              <Grid item sm={12}>
                <FormLabel
                  className={classes.labelBlack}
                  style={{ marginTop: '30px' }}
                  component='legend'
                >
                  Subtitles
                </FormLabel>
                {productMedia.map(
                  (formData: ProductVersionForm, index: number) => (
                    <SubtitlesForm
                      key={index}
                      product={product}
                      formData={formData}
                      index={index}
                      classes={classes}
                      handleInputChange={handleInputChange}
                      handleDropChange={handleDropChange}
                      handleFilmVersionAdd={handleFilmVersionAdd}
                      handleRemoveForm={handleRemoveForm}
                    />
                  )
                )}
              </Grid>
            </Grid>

            <button
              className={classes.dashboardButtonAddMedia}
              type='submit'
              onClick={handleAddForm}
            >
              + Add subtitles
            </button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(Styles as any)(AddSubtitles);
