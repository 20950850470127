import { Box, MenuItem, Typography } from '@material-ui/core';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { InputLabel, Select, Slider } from '@mui/material';
import AutocompleteInputSelects from 'components/controls/autocompleteInput/AutocompleteInputSelects';
import { LibraryProduct } from 'mapfilm-api';
import { project } from 'project';
import { Button } from 'rsuite';
import { isSeller } from 'services/authService';
import { FiltersSideBarStyles } from 'styles/filtersSideBar';

interface PropsTypes {
  countriesList: string[];
  filters: any;
  setFilters: any;
  defaultValues: any;
  themesList: any;
  setThemesList: any;
  products: LibraryProduct[];
  rightsOwners: string[];
  currentYear: number;
}

function FiltersSideBar(props: PropsTypes) {
  const {
    countriesList,
    filters,
    setFilters,
    defaultValues,
    themesList,
    setThemesList,
    products,
    rightsOwners,
    currentYear,
  } = props;

  const handleChange = (name: string, value: any) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, [name]: value }));
  };

  function handleResetFilters() {
    setFilters({
      rightsOwner: defaultValues[0]?.rightsOwner,
      regions: defaultValues[0]?.regions,
      year: defaultValues[0]?.year,
      duration: defaultValues[0]?.duration,
      themes: defaultValues[0]?.themes,
    });
  }

  return (
    <Box sx={FiltersSideBarStyles.filterContainer}>
      <Box sx={FiltersSideBarStyles.filterHeader}>
        <Button onClick={handleResetFilters} endIcon={<RestartAltIcon />}>
          Filters reset
        </Button>
      </Box>
      <Box sx={FiltersSideBarStyles.content}>
        <Box sx={FiltersSideBarStyles.row}>
          <Box sx={FiltersSideBarStyles.row.label}>
            <InputLabel id='yearSelect'>Year:</InputLabel>
            <Box>
              <Typography>
                {filters.year[0] + ' - ' + filters.year[1]}
              </Typography>
            </Box>
          </Box>
          <Box sx={FiltersSideBarStyles.row.filter}>
            <Box sx={FiltersSideBarStyles.row.filter.sliderContainer}>
              <Slider
                aria-label='yearSelect'
                min={defaultValues[0].year[0]}
                max={defaultValues[0].year[1]}
                size='medium'
                step={5}
                value={filters.year}
                onChange={(e: any) => {
                  handleChange('year', e.target.value);
                }}
                valueLabelDisplay='auto'
                sx={{
                  color: 'black',
                  '& .MuiSlider-valueLabelOpen': { color: 'white' },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={FiltersSideBarStyles.row}>
          <Box sx={FiltersSideBarStyles.row.label}>
            <InputLabel id='durationSelect'>Duration:</InputLabel>
            <Box>
              <Typography>
                {filters.duration[0] + ' - ' + filters.duration[1] + ' min '}
              </Typography>
            </Box>
          </Box>
          <Box sx={FiltersSideBarStyles.row.filter}>
            <Box sx={FiltersSideBarStyles.row.filter.sliderContainer}>
              <Slider
                aria-label='durationSelect'
                min={defaultValues[0].duration[0]}
                max={defaultValues[0].duration[1]}
                size='medium'
                step={5}
                value={filters.duration}
                onChange={(e: any) => {
                  handleChange('duration', e.target.value);
                }}
                valueLabelDisplay='auto'
                sx={{
                  color: 'black',
                  '& .MuiSlider-valueLabelOpen': { color: 'white' },
                }}
              />
            </Box>
          </Box>
        </Box>
        {project !== 'mfh' ? (
          <></>
        ) : (
          <>
            {isSeller() ? (
              <></>
            ) : (
              <Box sx={FiltersSideBarStyles.row}>
                <Box
                  sx={{
                    ...FiltersSideBarStyles.row.label,
                    ...FiltersSideBarStyles.select,
                  }}
                >
                  <InputLabel id='rightsOwnerSelect'>Rights owner</InputLabel>
                </Box>
                <Box sx={FiltersSideBarStyles.row.filter}>
                  <Select
                    variant='outlined'
                    labelId='rightsOwnerSelect'
                    sx={{
                      width: '100%',
                      color: 'rgba(0, 0, 0, 0.6)',
                      height: '48px',
                    }}
                    value={filters.rightsOwner}
                    onChange={(e) => {
                      handleChange('rightsOwner', e.target.value);
                    }}
                    displayEmpty
                  >
                    <MenuItem value='placeholder' style={{ color: '#808080' }}>
                      Select an option
                    </MenuItem>
                    {rightsOwners.map((owner) => (
                      <MenuItem key={owner} value={owner}>
                        {owner}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            )}

            <Box sx={FiltersSideBarStyles.row}>
              <Box sx={FiltersSideBarStyles.row.label}>
                <InputLabel id='regionSelect'>Regions</InputLabel>
              </Box>
              <Box sx={FiltersSideBarStyles.row.filter}>
                <AutocompleteInputSelects
                  id='regions'
                  label=''
                  name='regions'
                  pageType='product'
                  value={filters.regions}
                  options={countriesList}
                  placeholder={
                    filters.regions.length > 0 ? '' : 'Select regions'
                  }
                  disabled={false}
                  auth={true}
                  required={true}
                  getOptionLabel={(option) => option}
                  getOptionSelected={(option, value) => option === value}
                  onChange={(event, allValues, reason) =>
                    handleChange('regions', allValues)
                  }
                  selectedOptions={filters.regions || []}
                />
              </Box>
            </Box>
          </>
        )}

        <Box sx={FiltersSideBarStyles.row}>
          <Box sx={FiltersSideBarStyles.row.label}>
            <InputLabel id='themeSelect'>Themes</InputLabel>
          </Box>
          <Box sx={FiltersSideBarStyles.row.filter}>
            <AutocompleteInputSelects
              id='themes'
              label=''
              name='themes'
              pageType='product'
              value={filters.themes}
              options={themesList}
              placeholder={filters.themes?.length > 0 ? '' : 'Select themes'}
              disabled={false}
              auth={true}
              required={true}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) => option === value}
              onChange={(event, allValues, reason) =>
                handleChange('themes', allValues)
              }
              selectedOptions={filters.themes || []}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FiltersSideBar;
