import { Grid, Typography } from '@material-ui/core';
import { LibraryProduct } from 'mapfilm-api';
import { TypeContent } from 'models/enums';
import ProductItem from 'pages/products/ItemProduct';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface ProductListProps {
  products: (LibraryProduct & { nProducts?: number })[];
  isSimpleView: boolean;
  themesList: string[];
  lastProductRef: (node: any) => void;
  classes: any;
  typeContent: any;
}

const ProductList: React.FC<ProductListProps> = ({
  products,
  isSimpleView,
  themesList,
  lastProductRef,
  classes,
  typeContent,
}) => {
  if (typeof products !== 'undefined' && products.length === 0) {
    return (
      <Typography className={classes.noProducts}>No film products!</Typography>
    );
  }

  return (
    <Grid container spacing={!isSimpleView ? 3 : 6}>
      <Grid
        item
        xs={12}
        style={{
          margin: !isSimpleView ? '0 20px 0 0' : '24px 20px 0 0px',
        }}
      >
        <div
          style={{
            backgroundColor: !isSimpleView ? 'transparent' : '#FFFFFF',
            borderRadius: '5px',
            boxShadow: !isSimpleView
              ? 'none'
              : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            padding: !isSimpleView ? '0' : '0 20px 0 20px',
          }}
        >
          <Grid container spacing={!isSimpleView ? 3 : 6}>
            {products.map((product, index) => (
              <Grid
                key={product.id + index.toString()}
                item
                xs={!isSimpleView ? 12 : 4}
                ref={index === products.length - 1 ? lastProductRef : null}
              >
                <NavLink
                  key={index}
                  className={classes.productLink}
                  to={
                    typeContent === TypeContent.FILMS ||
                    typeContent === TypeContent.COLLECTIONFILMS
                      ? '/movies/' + product.id
                      : '/collections/' + product.id
                  }
                >
                  <ProductItem
                    key={index + '_product'}
                    poster={product.posterUrl}
                    title={product.name}
                    director={product.director}
                    duration={product.duration}
                    year={product.year}
                    synopsis={product.synopsis}
                    genres={product.genres}
                    nProducts={product.nProducts}
                    genreList={themesList}
                    movie={false}
                    isSimpleView={isSimpleView}
                    typeContent={typeContent}
                  />
                </NavLink>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default ProductList;
