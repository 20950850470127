import { LibraryProduct } from 'mapfilm-api';
import { EditCollectionWithPoster } from 'models/ProductsModel';
import moment from 'moment';

// * Format seconds to HH:mm:ss (H - hours, m - minutes, s - seconds)
export const formatSecondsToTime = (seconds = 0): string => {
  const mom = moment().startOf('day').seconds(seconds);
  let duration = '';

  if (mom.hours()) {
    duration = `${mom.hours()}h ${mom.minutes()}m`;
  } else {
    duration = `${mom.minutes()}m ${mom.seconds()}s`;
  }

  return duration;
};

// * Slider step calculator, ie, if number is too big, reduce step
export const stepCalculator = (num: number): number => {
  if (num > 999999) {
    return 10000;
  } else if (num > 14999) {
    return 1000;
  } else if (num > 9999) {
    return 500;
  } else if (num > 1499) {
    return 100;
  } else if (num > 999) {
    return 50;
  } else if (num > 499) {
    return 10;
  } else return 1;
};

export const titleCase = (str: string): string => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const normalizeMessage = (str: string): string => {
  return titleCase(str.replace(/([A-Z])/g, ' $1'));
};

export const getNewCheckedValues = (
  checkedValue: string,
  checkedValues: string[]
): string[] => {
  const newValues = checkedValues?.includes(checkedValue)
    ? checkedValues?.filter((value: string) => value !== checkedValue)
    : [...(checkedValues ?? []), checkedValue];

  return newValues;
};

export const removeHTML = (str: string): string => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || '';
};

export const languages_list = [
  { name: 'Afrikaans', code: 'af' },
  { name: 'Albanian', code: 'sq' },
  { name: 'Amharic', code: 'am' },
  { name: 'Arabic', code: 'ar' },
  { name: 'Aragonese', code: 'an' },
  { name: 'Armenian', code: 'hy' },
  { name: 'Asturian', code: 'ast' },
  { name: 'Azerbaijani', code: 'az' },
  { name: 'Basque', code: 'eu' },
  { name: 'Belarusian', code: 'be' },
  { name: 'Bengali', code: 'bn' },
  { name: 'Bosnian', code: 'bs' },
  { name: 'Breton', code: 'br' },
  { name: 'Bulgarian', code: 'bg' },
  { name: 'Catalan', code: 'ca' },
  { name: 'Central Kurdish', code: 'ckb' },
  { name: 'Chinese', code: 'zh' },
  { name: 'Corsican', code: 'co' },
  { name: 'Croatian', code: 'hr' },
  { name: 'Czech', code: 'cs' },
  { name: 'Danish', code: 'da' },
  { name: 'Dutch', code: 'nl' },
  { name: 'English', code: 'en' },
  { name: 'Esperanto', code: 'eo' },
  { name: 'Estonian', code: 'et' },
  { name: 'Faroese', code: 'fo' },
  { name: 'Filipino', code: 'fil' },
  { name: 'Finnish', code: 'fi' },
  { name: 'French', code: 'fr' },
  { name: 'Galician', code: 'gl' },
  { name: 'Georgian', code: 'ka' },
  { name: 'German', code: 'de' },
  { name: 'Greek', code: 'el' },
  { name: 'Guarani', code: 'gn' },
  { name: 'Gujarati', code: 'gu' },
  { name: 'Hausa', code: 'ha' },
  { name: 'Hawaiian', code: 'haw' },
  { name: 'Hebrew', code: 'he' },
  { name: 'Hindi', code: 'hi' },
  { name: 'Hungarian', code: 'hu' },
  { name: 'Icelandic', code: 'is' },
  { name: 'Indonesian', code: 'id' },
  { name: 'Interlingua', code: 'ia' },
  { name: 'Irish', code: 'ga' },
  { name: 'Italian', code: 'it' },
  { name: 'Japanese', code: 'ja' },
  { name: 'Kannada', code: 'kn' },
  { name: 'Kazakh', code: 'kk' },
  { name: 'Khmer', code: 'km' },
  { name: 'Korean', code: 'ko' },
  { name: 'Kurdish', code: 'ku' },
  { name: 'Kyrgyz', code: 'ky' },
  { name: 'Lao', code: 'lo' },
  { name: 'Latin', code: 'la' },
  { name: 'Latvian', code: 'lv' },
  { name: 'Lingala', code: 'ln' },
  { name: 'Lithuanian', code: 'lt' },
  { name: 'Macedonian', code: 'mk' },
  { name: 'Malay', code: 'ms' },
  { name: 'Malayalam', code: 'ml' },
  { name: 'Maltese', code: 'mt' },
  { name: 'Marathi', code: 'mr' },
  { name: 'Mongolian', code: 'mn' },
  { name: 'Nepali', code: 'ne' },
  { name: 'Norwegian', code: 'no' },
  { name: 'Occitan', code: 'oc' },
  { name: 'Oriya', code: 'or' },
  { name: 'Oromo', code: 'om' },
  { name: 'Pashto', code: 'ps' },
  { name: 'Persian', code: 'fa' },
  { name: 'Polish', code: 'pl' },
  { name: 'Portuguese', code: 'pt' },
  { name: 'Punjabi', code: 'pa' },
  { name: 'Quechua', code: 'qu' },
  { name: 'Romanian', code: 'ro' },
  { name: 'Romansh', code: 'rm' },
  { name: 'Russian', code: 'ru' },
  { name: 'Scottish Gaelic', code: 'gd' },
  { name: 'Serbian', code: 'sr' },
  { name: 'Serbo', code: 'sh' },
  { name: 'Shona', code: 'sn' },
  { name: 'Sindhi', code: 'sd' },
  { name: 'Sinhala', code: 'si' },
  { name: 'Slovak', code: 'sk' },
  { name: 'Slovenian', code: 'sl' },
  { name: 'Somali', code: 'so' },
  { name: 'Southern Sotho', code: 'st' },
  { name: 'Spanish', code: 'es' },
  { name: 'Sundanese', code: 'su' },
  { name: 'Swahili', code: 'sw' },
  { name: 'Swedish', code: 'sv' },
  { name: 'Tajik', code: 'tg' },
  { name: 'Tamil', code: 'ta' },
  { name: 'Tatar', code: 'tt' },
  { name: 'Telugu', code: 'te' },
  { name: 'Thai', code: 'th' },
  { name: 'Tigrinya', code: 'ti' },
  { name: 'Tongan', code: 'to' },
  { name: 'Turkish', code: 'tr' },
  { name: 'Turkmen', code: 'tk' },
  { name: 'Twi', code: 'tw' },
  { name: 'Ukrainian', code: 'uk' },
  { name: 'Urdu', code: 'ur' },
  { name: 'Uyghur', code: 'ug' },
  { name: 'Uzbek', code: 'uz' },
  { name: 'Vietnamese', code: 'vi' },
  { name: 'Walloon', code: 'wa' },
  { name: 'Welsh', code: 'cy' },
  { name: 'Western Frisian', code: 'fy' },
  { name: 'Xhosa', code: 'xh' },
  { name: 'Yiddish', code: 'yi' },
  { name: 'Yoruba', code: 'yo' },
  { name: 'Zulu', code: 'zu' },
];

export const content_rating = [
  { name: 'General audiences', code: 'G' },
  { name: 'Parental guidance suggested', code: 'PG' },
  { name: 'Parents strongly cautioned', code: 'PG-13' },
  { name: 'Restricted', code: 'R' },
  { name: 'No one 17 and under admitted', code: 'NC-17' },
];

export const mapCollectionFields = (collection: EditCollectionWithPoster) => {
  const { name, description, products, id, ...rest } = collection;

  const normalizedProducts = Array.isArray(products)
    ? products.map((product) =>
        typeof product === 'string' ? product : (product as LibraryProduct).id
      )
    : products
      ? [
          typeof products === 'string'
            ? products
            : (products as LibraryProduct).id,
        ]
      : [];

  return {
    ...rest,
    title: name,
    synopsis: description || '',
    products: normalizedProducts,
  };
};
