import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Link as NavLink } from 'react-router-dom';

import TextInput from 'components/controls/textInput/TextInput';
import { login } from 'services/authService';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';

import { project } from 'project';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Lock';
import { PRIMARY_COLOR } from 'styles/colors';

import { LoginModel } from 'mapfilm-api';

import { projectImageConfig } from 'utils/projectFieldsDefinitions';

import PartnersFooter from 'components/footer/partnersFooter';
import AuthStyles from 'styles/auth';
import './LoginPage.css';

interface OwnProps {
  history: any;
  classes: any;
}

const queryClient = new QueryClient();

const LoginPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <LoginPage {...props} />
    </QueryClientProvider>
  );
};

const LoginPage: React.FC<OwnProps> = ({ history, classes }) => {
  const [form, setForm] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    usernameError: '',
    passwordError: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const getProjectLogo = (project: string) => {
    const projectConfig =
      projectImageConfig[project] || projectImageConfig.default;
    return (
      <img
        src={projectConfig.logo}
        alt={`Logo ${projectConfig.name}`}
        className={project !== 'map' ? classes.logoAuth : ''}
      />
    );
  };

  const loginMutation = useMutation(
    (loginModel: LoginModel) => login(loginModel),
    {
      onError: (error: any) => {
        console.log('erro', error);
        // console.log('Loggin failed');
        setFormErrorMessage(
          '* You have entered an invalid username or password.'
        );
      },
      onSuccess: () => {
        setFormErrorMessage('');
        history.push('/');
        // console.log('Logged in');
      },
    }
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormErrorMessage('');

    const loginModel: LoginModel = {
      name: form.username,
      password: form.password,
    };
    loginMutation.mutate(loginModel);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormErrorMessage('');
    setForm((prevForm: any) => ({
      ...prevForm,
      [name]: value,
    }));

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [`${name}Error`]: '',
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword: any) => !prevShowPassword);
  };

  return (
    <div className={classes.authFormContainer}>
      <div className={classes.authFormBox}>
        <div className={classes.logoSection}>
          <NavLink to='/'>{getProjectLogo(project)}</NavLink>
        </div>

        <form className='auth-form' onSubmit={handleSubmit}>
          <TextInput
            type='text'
            label={
              project === 'mfh'
                ? 'Academic Email Address / Username'
                : 'Email Address / Username'
            }
            name='username'
            placeholder='Enter your email address or username'
            value={form.username}
            setInputValue={handleChange}
            disabled={false}
            required={true}
            autoComplete='email'
            startAdornment={
              <InputAdornment
                classes={{ positionStart: classes.positionStart }}
                position='start'
              >
                <EmailIcon />
              </InputAdornment>
            }
          />

          <TextInput
            type={showPassword ? 'text' : 'password'}
            label='Password'
            name='password'
            placeholder='Enter your password'
            value={form.password}
            setInputValue={handleChange}
            disabled={false}
            required={true}
            autoComplete='current-password'
            onChange={handleChange}
            startAdornment={
              <InputAdornment
                classes={{ positionStart: classes.positionStart }}
                position='start'
              >
                <PasswordIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                classes={{ positionEnd: classes.positionEndLogin }}
                position='end'
              >
                <IconButton
                  classes={{ root: classes.iconButton }}
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  {showPassword ? (
                    <VisibilityOff style={{ fill: 'black' }} />
                  ) : (
                    <Visibility style={{ fill: 'black' }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />

          {formErrorMessage ? (
            <Typography
              className={classes.authError}
              component='h1'
              variant='h6'
              style={{ color: 'red', paddingTop: '20px' }}
            >
              {formErrorMessage}
            </Typography>
          ) : (
            <></>
          )}

          <div className={classes.authOptions}>
            <Button
              classes={{
                root: classes.submit,
              }}
              type='submit'
              variant='contained'
              color='default'
              disabled={loginMutation.isLoading}
            >
              {loginMutation.isLoading ? (
                <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                  <CircularProgress
                    sx={{
                      color: PRIMARY_COLOR,
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                </Box>
              ) : (
                'Sign in'
              )}
            </Button>
            <div className={classes.signUpLinkContainer}>
              <Typography className={classes.authTxt}>
                {"Don't have an account?"}
              </Typography>
              <Link
                classes={{ root: classes.authLink }}
                component={NavLink}
                to='/auth/register'
              >
                Sign up
              </Link>
            </div>
          </div>

          <div className={classes.forgotContainer}>
            <Link
              classes={{ root: classes.forgotLink }}
              component={NavLink}
              to='/auth/resetPassword'
            >
              Forgot your password?
            </Link>
          </div>
        </form>
      </div>
      {project === 'mfh' && <PartnersFooter />}
    </div>
  );
};

export default withStyles(AuthStyles as any)(LoginPageWrapper);
