import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

interface ViewChangerProps {
  isSimpleView: boolean;
  handleToggleView: (
    event: React.MouseEvent<HTMLElement>,
    newView: boolean | null
  ) => void;
  className?: string;
}

const ViewChanger: React.FC<ViewChangerProps> = ({
  isSimpleView,
  handleToggleView,
  className,
}) => {
  return (
    <ToggleButtonGroup
      value={isSimpleView}
      exclusive
      onChange={handleToggleView}
      aria-label='view toggle'
      color='primary'
      className={className}
    >
      <ToggleButton value={false} aria-label='list view'>
        <ViewListIcon />
      </ToggleButton>
      <ToggleButton value={true} aria-label='module view'>
        <ViewModuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ViewChanger;
