import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Loading from 'components/loading/Loading';
import { TypeContent } from 'models/enums';
import Downloads from 'pages/downloads/Downloads';
import LibraryPage from 'pages/library/LibraryPage';
import CreateLTemplatePage from 'pages/licenses/forms/CreateLTemplatePage';
import CustomizeLicensePage from 'pages/licenses/forms/CustomizeLicensePage';
import CLlist from 'pages/licenses/view/CLlist';
import LicenseTemplate from 'pages/licenses/view/LicenseTemplate';
import LTlist from 'pages/licenses/view/LTlist';
import PLlist from 'pages/licenses/view/PLlist';
import SignLicense from 'pages/licenses/view/SignLicense';
import Management from 'pages/management/Management';
import PageNotFound from 'pages/pagenotfound/PageNotFound';
import ProductPage from 'pages/product/ProductPage';
import CreateCollectionPage from 'pages/products/CreateCollectionPage';
import CreateProductPage from 'pages/products/CreateProductPage';
import EditCollectionPage from 'pages/products/EditCollectionPage';
import EditProductPage from 'pages/products/EditProductPage';
import ProfilePage from 'pages/profile/Profile';
import { project } from 'project';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import {
  hasLicense,
  isAdmin,
  isBuyer,
  isLoggedIn,
  isSeller,
  isStaff,
} from 'services/authService';

const queryClient = new QueryClient();

const AppRouterWrapper: React.FC = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter {...props} />
    </QueryClientProvider>
  );
};

const AppRouter = (props: any) => {
  const location = useLocation();

  const isMFHProject = project === 'mfh';

  const {
    data: hasLicenseSigned,
    isLoading,
    error,
    refetch: refetchLicenseStatus,
  } = useQuery(['licenseStatus'], hasLicense, {
    enabled: isMFHProject,
  });

  if (!isLoggedIn()) {
    return <Redirect to='/auth/login' />;
  }
  const currentPath = location.pathname;

  const routes = [];

  if (isLoading) {
    return <Loading />;
  }

  if (
    isStaff() &&
    isBuyer() &&
    (hasLicenseSigned == null || hasLicenseSigned === false) &&
    project === 'mfh' &&
    currentPath !== '/signLicenses'
  ) {
    routes.push(
      <Redirect
        key='redirect-signLicenses'
        to={{ pathname: '/signLicenses' }}
      />
    );
  }

  if (isAdmin()) {
    routes.push(
      <Redirect key='redirect-management' exact from='/' to='/management' />,
      <Route
        key='route-management'
        exact
        path='/management'
        component={Management}
      />
    );
  } else if (
    isStaff() &&
    isBuyer() &&
    (hasLicenseSigned == null || hasLicenseSigned === false) &&
    project === 'mfh'
  ) {
    routes.push(
      <Route
        key='route-signLicenses'
        exact
        path='/signLicenses'
        render={(routeProps) => (
          <SignLicense
            {...routeProps}
            refetchLicenseStatus={refetchLicenseStatus}
          />
        )}
      />
    );
  } else {
    routes.push(
      <Route
        key='route-catalogue'
        exact
        path='/catalogue'
        render={(props) => (
          <LibraryPage {...props} typeContent={TypeContent.FILMS} />
        )}
      />,
      <Route
        key='route-collections'
        exact
        path='/collections'
        render={(props) => (
          <LibraryPage {...props} typeContent={TypeContent.COLLECTIONS} />
        )}
      />,
      <Route
        key='route-productPage'
        exact
        path='/collections/:id'
        render={(props) => (
          <LibraryPage {...props} typeContent={TypeContent.COLLECTIONFILMS} />
        )}
      />
    );

    if (isSeller()) {
      routes.push(
        <Route
          key='route-createProductInCollection'
          exact
          path='/collection/:collectionId/movie/create'
          component={CreateProductPage}
        />,
        <Route
          key='route-createProduct'
          exact
          path='/movie/create'
          component={CreateProductPage}
        />,
        <Route
          key='route-createCollection'
          exact
          path='/collection/create'
          component={CreateCollectionPage}
        />,
        <Route
          key='route-editCollection'
          exact
          path='/collections/:id/edit'
          component={EditCollectionPage}
        />,
        <Route
          key='route-editProduct'
          exact
          path='/movies/:id/edit'
          component={EditProductPage}
        />
      );
    }

    routes.push(
      <Route
        key='route-customizeLicense'
        exact
        path='/movies/:id/customizeLicense'
        component={CustomizeLicensePage}
      />,
      <Route
        key='route-createLicense'
        exact
        path='/movies/:id/createLicense'
        component={CreateLTemplatePage}
      />,
      <Route
        key='route-productPage'
        exact
        path='/movies/:id'
        component={ProductPage}
      />,
      <Route
        key='route-myBespokeLicenses'
        exact
        path='/movies/:productId/myBespokeLicenses/:templateId'
        component={LicenseTemplate}
      />,
      <Route
        key='route-profile'
        exact
        path='/profile'
        component={ProfilePage}
      />,
      <Route
        key='route-myBespokeLicenses2'
        exact
        path='/myBespokeLicenses/:templateId'
        component={LicenseTemplate}
      />,
      <Route
        key='route-myBespokeLicensesList'
        exact
        path='/myBespokeLicenses'
        component={LTlist}
      />,
      <Route
        key='route-myCustomLicenses'
        exact
        path='/myCustomLicenses'
        component={CLlist}
      />,
      <Route
        key='route-myDownloads'
        exact
        path='/myDownloads'
        component={Downloads}
      />,
      <Route
        key='route-myPendingLicenses'
        exact
        path='/myPendingLicenses'
        component={PLlist}
      />
    );

    if (currentPath === '/') {
      routes.push(
        <Redirect
          key='redirect-catalogue'
          to={project === 'scene' ? '/collections' : '/catalogue'}
        />
      );
    }
  }

  return (
    <Switch>
      {routes}
      <Route key='route-pageNotFound' component={PageNotFound} />
    </Switch>
  );
};

export default AppRouterWrapper;
